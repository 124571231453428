.BasicDropdown {
  background-color: var(--color-white);
  color: var(--color-dark);
  box-shadow: 0px 0px 12px rgba(var(--color-black-rgb), 0.16);
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 5px;
  border-radius: 8px;
  z-index: 1;
}
