@function get-vw($target) {
  $vw-context: (1141 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}

.Loader {
  background: var(--color-grey-light-3);
  overflow: hidden;
  border-radius: 20px;

  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(rgba(#ffffff, 0.2)),
      to(transparent)
    );

    background: linear-gradient(
      90deg,
      transparent,
      rgba(#ffffff, 0.2),
      transparent
    );

    /* Adding animation */
    animation: loading 0.8s infinite;
  }
}

.LoaderSVG {
  width: 30px;
  height: 30px;
  display: inline-block;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.PeaLoaderRectangle {
  height: get-vw(297px);
  width: get-vw(220px);
  margin: 0 auto;
}

.PeaLoaderSquare {
  height: get-vw(220px);
  width: get-vw(220px);
  margin: 0 auto;
}

.SearchResultLoaderWrapper {
  display: flex;
  padding: 10px 0;

  .SearchResultLoaderImage {
    width: 56px;
    height: 55px;
    display: table;
  }

  .SearchResultLoaderDetailsWrapper {
    padding: 0px 25px 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .Loader {
      margin: 2.5px 0;
    }
  }

  .SearchResultLoaderTitle {
    width: 100%;
    height: 22px;
  }

  .SearchResultLoaderCompany {
    width: 75%;
    height: 15px;
  }
}

.BroadcastPeaLoader {
  min-width: 130px;
  width: 130px;
  height: 190px;
}

@media screen and (max-width: 1141px) {
  .PeaLoaderRectangle {
    height: 297px;
    width: 220px;
  }

  .PeaLoaderSquare {
    height: 220px;
    width: 220px;
  }
}

@media only screen and (max-width: 375px) {
  .PeaLoaderRectangle {
    height: 425px;
    // width: 300px;
    width: calc(100vw - 10vw) !important;
  }

  .PeaLoaderSquare {
    height: 300px;
    // width: 300px;
    width: calc(100vw - 10vw) !important;
  }
}
