.SelectBy {
  display: flex;
  // min-width: 250px;
  justify-content: space-evenly;
  align-items: center;

  .OrderLabel {
    white-space: nowrap;
    padding: 9px 20px 4px 20px;
    border: px solid #cccccc;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-right: none;
    color: gray;
    font-size: 1.3rem;
  }

  .react-select {
    &__menu {
      z-index: 999;
    }
  }

  :global(.basic-single) {
    width: 190px;

    & > div {
      // border-top-right-radius: 8px;
      // border-top-left-radius: 0px;
      // border-bottom-left-radius: 0px;
      // border-bottom-right-radius: 8px;
      // padding-left: 7px;
      // color: hsl(0, 0%, 60%) !important;
      border-radius: 8px;
      padding-top: 1px;
      padding-bottom: 0px;
      border-width: 2px;
      font-size: 1.3rem;
      color: var(--color-brown-gray) !important;
      font-weight: bold;
    }
  }

  :global(.basic-company) {
    width: 140px;

    & > div {
      font-size: 13px;
      border-width: 1px;
    }
  }

  .basic-pod {
    width: 128px;

    .react-select__control {
      font-size: 13px;
      border-width: 1px;
      border-radius: 5px;
      border-color: var(--color-gray-light-2);
      color: var(--color-brown-gray);
      padding-right: 8px;
      font-weight: 600;
      min-height: 35px;
      max-height: 35px;

      .react-select__indicators {
        color: var(--color-gray-light-2);

        svg {
          width: 10px;
          height: 6px;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .SelectBy {
    display: flex;
    // min-width: 200px;
    justify-content: space-evenly;
    align-items: center;

    .OrderLabel {
      white-space: nowrap;
      padding: 7px 10px 5px 10px;
      border: 2px solid #cccccc;
      border-top-left-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 0px;
      border-right: none;
      color: gray;
      font-size: 1rem;
    }

    :global(.basic-single) {
      width: 140px;

      & > div {
        // border-top-right-radius: 8px;
        // border-top-left-radius: 0px;
        // border-bottom-left-radius: 0px;
        // border-bottom-right-radius: 8px;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 1rem;
        padding: 0px;
        // color: hsl(0, 0%, 60%) !important;
        color: var(--color-brown-gray) !important;
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .SelectBy {
    :global(.basic-pod) {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .SelectBy {
    .basic-pod {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .SelectBy {
    min-width: 200px;
    justify-content: space-evenly;
    align-items: center;

    .OrderLabel {
      white-space: nowrap;
      padding: 7px 10px 5px 10px;
      border: 2px solid #cccccc;
      border-top-left-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 0px;
      border-right: none;
      color: gray;
      font-size: 15px;
    }

    .basic-single {
      min-width: 140px;
      width: 100%;

      & > div {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 15px;
        padding: 0px;
        color: hsl(0, 0%, 60%) !important;
        font-weight: bold;
      }
    }
  }
}
