@function get-vw($target) {
  $vw-context: (1141 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}

.bg-video__content {
  object-fit: cover;
  border-radius: 20px;

  &:focus {
    outline: none;
  }
}

.VideoWrapper {
  width: 100%;
  position: relative;

  .ComingSoonMask {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    z-index: 99;
    position: absolute;
    font-size: 1.2rem;
    border-radius: 20px;
  }

  .FreshPeaContainer,
  .LabelTagsContainer {
    position: absolute;
    top: auto;
    right: 0;
    display: flex;
    margin: 10px;
    flex-direction: column;
    justify-content: center;
  }

  .FreshPeaContainer {
    top: auto !important;
  }

  .ChannelTag {
    background: #6b63f7;
    padding: 3px 16px;
    margin: 5px;
    border-radius: 20px;
    color: #fff;
  }

  .TargetEffectTag {
    background: #4ed986;
    padding: 3px 16px;
    margin: 5px;
    border-radius: 20px;
    color: #fff;
  }

  .TargetEffectTag {
    background: #4ed986;
  }

  .TargetSortTag {
    background: #0c66e4;
    padding: 3px 16px;
    margin: 5px;
    border-radius: 20px;
    color: #fff;
  }

  .TargetSortTag {
    background: #0c66e4;
  }
}

.CategoryIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  margin: 30px;
}

.Square,
.Rectangle {
  width: 100%;
}

.SquarePeaPageMobile {
  height: 314px;
  width: 314px;
}

.RectanglePeaPageMobile {
  height: 439px;
  width: 314px;
}

// .SquareModal {
//   height: 245px;
//   width: 245px;
// }

// .RectangleModal {
//   height: 370px;
//   width: 245px;
// }

//.Square,
//{
//  //height: 220px;
//  //width: 220px;
//  height: 19.29vw;
//  width: 19.29vw;
//}
//
//.Rectangle {
//  //height: 340px;
//  //width: 220px;
//  height: 29.81vw;
//  width: 19.29vw;
//}

.Company {
  height: 380px;
  width: 380px;
  position: relative;
  border-radius: 20px;

  iframe,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    padding: 0;
    border-radius: 20px;
  }
}

.UserDetailsContainerInModal {
  position: absolute;
  //height: 0;
  z-index: 20;
  width: 100%;
  bottom: 0;

  .UserDetailsWrapperInModal {
    position: relative;
    bottom: 0;
    height: 75px;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.5))
    );
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    border-radius: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .UserPicture {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      cursor: pointer;
      background-color: var(--color-white);
      background: var(--color-white);
    }

    .UserName {
      font-family: 'Work Sans', sans-serif;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);
      margin-left: 10px;

      a {
        color: var(--color-white);
      }
    }

    .VideoFooterInModal {
      display: flex;
      flex-direction: row;
      height: 100%;
      overflow: hidden;
      align-items: center;
      padding: 0 15px;
      //min-width: 220px;
      //width: 19.29vw;
      /* width: 314px; */

      .VideoFooterDetailsWrapperInModal {
        flex: 1;
        margin-top: 12px;
      }

      .HeartIcon {
        background-color: white;
        border-radius: 5px;
        font-size: 30px;
        padding: 8px;
        opacity: 0.77;
        vertical-align: middle;
        cursor: pointer;
        float: right;
        margin-top: 12px;
        transition: opacity, background-color 0.3s ease;

        &:hover {
          opacity: 0.9;
        }

        &:global(.active) {
          opacity: 0.9;
          color: var(--color-primary);
        }
      }
    }
  }
}

.UserDetailsContainer {
  position: absolute;
  //height: 0;
  z-index: 20;
  width: 100%;
  bottom: 0;

  .UserDetailsWrapper {
    position: relative;
    bottom: 0;
    height: 75px;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.5))
    );
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    border-radius: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .UserPicture {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      background-color: var(--color-white);
    }

    .UserName {
      font-family: 'Work Sans', sans-serif;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);
      margin-left: 10px;

      a {
        color: var(--color-white);
      }
    }

    .VideoFooter {
      display: flex;
      flex-direction: row;
      height: 100%;
      overflow: hidden;
      align-items: center;
      padding: 0 15px;
      //min-width: 220px;
      //width: 19.29vw;

      .VideoFooterDetailsWrapper {
        flex: 1;
        margin-top: 12px;
      }

      .HeartIcon {
        background-color: white;
        border-radius: 5px;
        font-size: 30px;
        padding: 8px;
        opacity: 0.77;
        vertical-align: middle;
        cursor: pointer;
        float: right;
        margin-top: 12px;
        transition: opacity, background-color 0.3s ease;

        &:hover {
          opacity: 0.9;
        }

        &:global(.active) {
          opacity: 0.9;
          color: var(--color-primary);
        }
      }
    }
  }
}

.DefaultTooltip {
  padding: 15px;
  width: 280px;
  border-radius: 20px;

  &:global(.popover),
  &:global(.popover):global(.arrow) {
    border-color: white;
  }
}

.Square,
.companySquare,
.SquareModal {
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  border-radius: 20px;

  > div:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    iframe,
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      padding: 0;
      border-radius: 20px;
    }
  }

  iframe,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    padding: 0;
    border-radius: 20px;
  }
}

.Rectangle,
.companyRectangle,
.RectangleModal {
  // height: get-vw(297px);
  // width: get-vw(220px);
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 125%;
  border-radius: 20px;

  > div:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    iframe,
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      padding: 0;
      border-radius: 20px;
    }
  }

  iframe,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    padding: 0;
    border-radius: 20px;
  }
}
// @media only screen and (min-width: 1142px) {
// }

@media only screen and (max-width: 1141px) {
  // .Square,
  // .companySquare,
  // .SquareModal {
  //   height: 220px;
  //   width: 220px;
  // }

  // .Rectangle,
  // .companyRectangle,
  // .RectangleModal {
  //   height: 297px;
  //   width: 220px;
  // }

  .UserDetailsContainer {
    display: none;
  }
}

@media only screen and (max-width: 760px) {
  .Company {
    height: 360px;
    width: 340px;
  }

  .companyRectangle {
    width: 100%;
  }

  .VideoWrapper {
    margin: 0 auto;
  }

  .VideoEmbed {
    padding-right: 0;
    margin-left: 0;
  }
}

:global {
  .carousel-control-next {
    bottom: 60px;
  }

  .carousel-control-prev {
    bottom: 60px;
  }
}

@media only screen and (max-width: 375px) {
  .Company {
    height: 340px;
    width: 300px;
  }

  // .Square,
  // .companySquare,
  // .SquareModal {
  //   height: 300px;
  //   width: 300px;
  // }

  // .Rectangle,
  // .companyRectangle,
  // .RectangleModal {
  //   height: 425px;
  //   width: 300px;
  // }
}

.bgVideoWrapper {
  > div:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    iframe,
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      padding: 0;
      border-radius: 20px;
    }
  }
}
