.footerContainer {
  background: #262627;
  margin-top: 104px;
}

.footerContent {
  max-width: 70%;
  margin: 0 auto;
  padding: 32px 0;
}

.footerServicesContainer {
  max-width: 60%;
  margin: 0 auto;
  padding: 32px 0;
}

.services {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.servicesDown {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.services p {
  text-transform: uppercase;
  text-align: justify;
  color: #fff;
  font-weight: 600;
}

.servicesDown p {
  text-transform: uppercase;
  text-align: justify;
  color: #71c885;
  font-weight: 600;
}

.img {
  padding-top: 5px;
  margin-right: 20px;
}

.footerBottomContent {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 14px;
}

.footerBottomContent li {
  list-style: none;
  color: #f4f4f4;
  margin: 15px 0;

  .footerLink {
    text-decoration: none;
    color: #f4f4f4;
    cursor: pointer;
  }
}


.footerBottomContent ul h3 {
  margin-bottom: 20px;
}

.footerBottomContent li p {
  margin-left: 15px;
}

.footerBottomContent sup {
  color: #71c885;
}

.footerBottom {
  color: #c7c4bf;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 30px;
  font-size: 12px;

  
  .footerBottomLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;

    .FooterMoreContainer {
      vertical-align: middle;
      display: inline-block;
    }
  }

}


.FooterOptionHeading {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.07em;
}

.MiniLogo{
  width: 36px;
  height: 36px;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .footerServicesContainer {
    min-width: 80%;
  }

  .footerContent {
    min-width: 95%;
  }

  .footerBottom {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 821px) {
  .footerServicesContainer {
    min-width: 90%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    // align-items: center;
  }

  .services, .servicesDown {
    display: flex;
    flex-direction: column;
  }

  .servicesDown{
    justify-content: flex-start;
    align-items: flex-start;
  }
  .img {
    padding-top: 0px;
    margin-right: 0px;
  }

  .footerContent {
    min-width: 90%;
    margin-top: 60px;
    padding: 0px;

    .footerBottomContent{
      margin-top: 20.44px;
      .LeftOptions{
        display: flex;
        flex-direction: column;
        padding: 0px;

        ul{
          padding: 0px;
          .FooterOptionHeading {
            letter-spacing: -1px;
            color: #EFEFEF;

          }
          li{
            margin-top: 12px;
          }
          li .footerLink{
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -1px;
            color: #EFEFEF;

          }
        }
      }
  
      .RightOptions{
        display: flex;
        flex-direction: column;
        padding: 0px;

        ul{
          padding: 0px;
          .FooterOptionHeading {
            letter-spacing: -1px;
          }
          li .footerLink{
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -1px;
            color: #EFEFEF;

          }
        }
      }
    }
      
  }

  .MiniLogo{
    width: 34px;
    height: 34px;
  }

  .footerBottom {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    padding-bottom: 24px;
    .footerBottomLinks {
      width: 90%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      .FooterMoreContainer{
        vertical-align:baseline;
      }
    } 
  }
}