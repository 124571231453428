.share-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #efefef;
  transition: height 0.3s ease-out;
  overflow: hidden;
  z-index: 999;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.share-modal.open {
  padding: 15px;
  height: 150px; /* Set the desired height for the open share modal */
}

.share-options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.share-options li {
  padding: 10px;
  cursor: pointer;
  /* Add other styles as needed */
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  /* Add other styles as needed */
}
