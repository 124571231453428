.mentions-input {
  &__highlighter {
    // display: none;
    min-height: 60px !important;
    height: 100% !important;
  }

  &__input {
    // position: static !important;
    padding: 6px 125px 6px 15px !important;
    min-height: 60px !important;
    height: 100% !important;
    border-color: var(--color-gray-light-1);
    border-radius: 5px !important;
    font-size: 13px !important;
    overflow-y: auto !important;
  }

  &__suggestions {
    top: 25px !important;

    &__list {
      border: 1px solid var(--color-gray-light-2);
      height: 100% !important;
      max-height: 100% !important;
    }

    &__item {
      padding: 3px 6px !important;
      height: fit-content !important;
      text-align: left !important;

      &__display {
        height: fit-content !important;
        position: static !important;
        display: block !important;
      }
    }

    &__item--focused {
      background-color: var(--color-gray-light-1);
      width: 100% !important;
    }
  }
}
