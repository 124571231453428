.HeaderBackButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 15px 10px 15px;
  align-items: center;

  .BackButton {
    padding: 10px 8px;
    border-radius: 4px;
    width: 30px;
    height: 30px;
  }
}
