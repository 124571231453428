.SignUp {
  width: 660px;
  height: 454px;
  text-align: center;
  display: flex;

  :global {
    input:focus {
      outline-offset: 0;
    }
  }
}

.signInLink {
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    border-bottom: 1px solid #000;
  }
}

.SignUp__Info {
  width: 50%;
  font-size: 1.32vw;
  color: #2f2e41;
  padding-right: 15px;
  text-align: center;

  .title,
  .undertext,
  .normal {
    font-family: "Work Sans", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    font-weight: 600;
  }

  .normal {
    font-weight: 400;
  }

  .undertext {
    display: block;
    font-weight: 400;
  }

  .SideImageLogo {
    padding-top: 0.9vw;
  }
}

.SignUp__Form {
  width: 50%;
  padding: 0 0 0 60px;

  .SignUp__Form--Image {
    margin-bottom: 50px;

    .ImageLogo {
      min-width: 176px;
    }
  }

  input {
    font-family: "Work Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--brown-grey);
    outline: unset !important;
    width: 100%;
    height: 30px;
  }

  form {
    button {
      font-family: "Work Sans", sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      width: 100%;
      height: 30px;
    }
  }

  :global {
    .alert-danger {
      font-size: 11px;
      word-break: break-all;
    }
  }

  button {
    padding: 0.5rem 1rem;
  }

  .ErrorMessage {
    color: var(--color-danger);
    text-align: left;
    font-size: 0.7rem;
  }

  .ErrorMessageActionLevel {
    margin: 0 0 15px;
    font-size: 0.8rem;
    color: var(--color-danger);
  }

  .SuccessMessageActionLevel {
    margin: 0 0 15px;
    font-size: 0.8rem;
    color: var(--color-secondary-light);
  }
}

.BusinessAccountEmailWarning,
.CreateAccountText {
  color: red;
  font-family: "Work Sans", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.BusinessAccountEmailWarning {
  a {
    color: var(--color-primary) !important;
    text-decoration: underline !important;
    text-decoration-style: dashed !important;
    cursor: pointer;
  }
}

.CreateAccountText {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  color: var(--greyish-brown);
  max-width: 210px;
  text-align: center;
  z-index: 2;

  a,
  b {
    display: inline-block;
    padding-left: 5px;
    color: var(--color-grey-dark-3);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-top: 10px;
  }
}

.BusinessAccountSelectWrapper {
  margin-bottom: 10px;
}

.SideImageLogo-mobile {
  width: 30%;
}

.LinkedinButtonWrapper {
  color: var(--greyish-brown);
  position: relative;
  padding: 10px 0;

  .LinkedinLogoWrapper {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  button {
    padding: 8px;
    display: flex;

    span {
      flex: 1;
    }
  }
}

.LinkedInWrapper{
  display: flex;
  vertical-align: baseline;
  column-gap: 11px;
}

.SignUpButtonWrapper {
  color: var(--white);
  button {
    padding: 0.5rem 1rem;
  }
}

.selectRoleBusinessWrapper {
  :global {
    .custom {
      box-shadow: unset;

      &:hover {
        box-shadow: unset;
      }

      &__control {
        flex-wrap: unset;
        height: 30px !important;
        min-height: 30px !important;
        border-radius: 5px;
      }

      &__placeholder {
        color: var(--brown-grey);
        font-family: "Work Sans", sans-serif;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

      &__indicator {
        color: var(--brown-grey);
      }

      &__value-container {
        flex-wrap: unset;
        max-height: 100%;
      }
    }
  }
}

@media (min-width: 1199px) {
  .SignUp {
    padding: 60px 60px 15px 50px;
    max-height: fit-content;
    height: fit-content;

    .SignUp__Info {
      section {
        b,
        p {
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .SignUp {
    padding: 60px 45px 20px 30px;
    height: 59.26vw;
  }

  .SignUp__Info {
    padding: 0;

    .title,
    .undertext {
      text-align: center;
    }
  }

  .SignUp__Form {
    height: 100%;
    padding: 0 0 0 35px;

    form {
      button {
        font-size: 13px;
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .SignUp {
    width: 100%;
    min-width: unset;
    min-height: unset;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;

    nav {
      box-shadow: 0 1px 4px 0 rgba(142, 142, 142, 0.48);
      background-color: var(--white);
    }
  }

  .SignUp__Info {
    padding-top: 50px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .undertext {
      padding-left: 0;
      text-align: center;
    }

    h3 {
      margin-bottom: 5px;
      font-size: 28px;
      font-family: "Work Sans", sans-serif;
    }

    p {
      height: 18px;
      font-family: "Work Sans", sans-serif;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--dark);
      margin-bottom: 20px;
    }
  }

  .SignUp__Form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 0 40px;

    input {
      font-family: "Work Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--brown-grey);
      border-radius: 5px;
      border: 1px solid var(--very-light-pink);
      margin-bottom: 10px;
    }

    form {
      button {
        border-radius: 5px;
        font-family: "Work Sans", sans-serif;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 10px;
      }
    }

    .LinkedinButtonWrapper {
      padding: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 15px;
          background-color: var(--greyish-brown);
        }

        div {
          position: initial !important;
          font-family: "Work Sans", sans-serif;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--greyish-brown);
        }
      }
    }

    .CreateAccountText {
      margin-top: 20px;
      font-family: "Work Sans", sans-serif;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--greyish-brown);
      z-index: 2;
    }
  }

  .footerImageMobile {
    margin-top: -33px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .SignUp__Form--Image {
    display: flex;
    justify-content: center;
  }

  .SignUp__Info .undertext {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 760px) {
  .SignUp {
    padding: 0;
    height: auto;
    overflow-y: auto;
    -webkit-overflow-y: auto;
  }

  .footerImageWrapper {
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .footerImageMobile {
    width: 70%;
    height: auto;
    margin: 0 auto;
    margin-top: -33px;
  }

  .footerImageMobileSafari {
    width: 80vw;
    height: 30vh;
    margin: 0 auto;
    margin-top: -60px;
  }

  .PeaLogoLetterIn {
    width: 10rem;
    height: auto;
    margin: 0 auto;
  }

  .PeaLogoLetterInSafari {
    width: 9rem;
    height: auto;
    margin: 0 auto;
  }
}

@media (max-width: 760px){
  .SignUp{
    background: no-repeat center url("../../../icons/signup_cover.png") ;
    background-position-y: 7px;
    background-position-x: 12px;
    background-size: 100%;
  }
}
@media only screen and (max-width: 320px) {
  .PeaLogoLetterIn {
    width: 8rem;
    height: auto;
    margin: 0 auto;
  }

  .PeaLogoLetterInSafari {
    width: 7rem;
    height: auto;
    margin: 0 auto;
  }
}
