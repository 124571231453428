.heroContainer {
    min-height: calc(100vh - 110px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    margin: 0 auto;
}

.title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 387px;
    color: #2f2e41;
}

.title span {
    color: #4ed986;
}

.subtitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;

    letter-spacing: 0.012em;

    color: #2F2E41;

    text-align: left;
    max-width: 429px;
    margin-top: 27px;
}

.callToAction {
    display: flex;
    align-items: center;
    margin-top: 33px;
}

.StartButton {
    font-size: 16px;
    font-weight: 600;
    padding: 12px 15px;
    height: auto;
}

.subCTA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px;
}

.subCTA .MainSpan {
    margin-left: 10px;
    color: #2f2e41;
    font-weight: 500;
    font-size: 14px;
}


.microHero{
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;    
    margin-left: 4px;
    color: #2f2e41;
}
.SwooshContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.SwooshTopLine,
.SwooshCenterLine,
.SwooshBottomLine {
    display: flex;
    column-gap: 4rem;

}

.SwooshTopLine {
    justify-content: space-between;
}

.SwooshCenterLine {
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: bottom;
}

.SwooshBottomLine {
    justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .heroContainer {
        width: 80%;
        margin-top: 90px;
    }
}

@media screen and (max-width: 821px) {
    .title {
        font-weight: 700;
font-size: 28px;
line-height: 39px;
    }
    .subtitle{
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #000000;
        margin-top: 16px;
    }
    .heroContainer {
        width: 90%;
        min-height: auto;
        margin-bottom: 40px;
        display: block;
        .heroText{
            .title{
                padding-top: 47px;
            }
        }
    }

    .callToAction{
        flex-direction: column;
        // align-items: baseline;
        align-items: flex-start;
    }
    .subCTA{
        margin-top: 29px;
        margin-left: 0;
    }
    .SwooshContainer{
        margin-top: 54px;
    }
}