.TooltipUserPicture {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.TooltipUserName {
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  vertical-align: middle;

  a {
    color: var(--color-gray-brown-2);
  }
}

.FollowingButtonWrapper {
  float: right;

  .FollowButton {
    //padding: 0.3rem 1rem;
    padding: 10px;
    width: 92px;
    height: 36px;
    font-size: 11px;
    border: 2px solid var(--color-weird-green);
    color: var(--color-weird-green);
    //font-size: 0.8vw;

    &:hover {
      background: var(--color-weird-green);
      color: #fff;
    }
  }

  .FollowingButton {
    color: #fff;

    &:hover {
      border: none;
      color: #fff;
    }
  }
}

.TooltipPicturesWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;

  .TooltipPea {
    width: 70px;
    height: 65px;
    border-radius: 5px;
    cursor: pointer;
  }

  .TooltipPeaLoader {
    width: 75px;
    height: 75px;
    border-radius: 5px;
  }
}

.TooltipUserStats {
  display: flex;
  color: var(--color-brown-gray);
  padding-top: 15px;

  .UserStat {
    padding-right: 10px;

    .UserStatIcon {
      margin-right: 4px;
    }
  }
}
