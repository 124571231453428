.Header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 1.5% 0 1.5%;
  align-items: center;

  .menuItem {
    margin: 10px 0;
    color: var(--color-dark);
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
  }

  .companyItem {
    display: flex;
    justify-content: space-between;
  }

  .menuItemColorPurple {
    color: var(--color-primary);
  }

  .dropdownIconWrapper {
    color: var(--color-dark);
    opacity: 0.3;

    .dropdownIcon {
      font-size: 12px;
      font-weight: lighter;
    }
  }

  a {
    text-decoration: none;
    color: var(--color-dark);
  }

  .HeaderSort {
    margin-right: 15px;
  }

  @media only screen and(min-width: 1024px) {
    height: 55px;
  }

  @media only screen and(min-width: 1440px) {
    height: 65px;
  }

  @media only screen and(max-width: 1023px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.HomeHeader {
  padding-left: 40px;
  padding-right: 40px;
}

.MiniLogo {
  flex: 0 0 28px;
  width: 28px;
  height: 28px;
  min-width: unset !important;
}

.Gallery,
.Company,
.Interests,
.Settings,
.Pod {
  border-bottom: 1px solid var(--color-gray-light-1);
}

.AuthButtons {
  display: flex;
  align-items: center;
}

.HelpWrapper {
  display: flex;

  .HeaderDropdown,
  .SkyDropDropdown,
  .NotificationsDropDropdownMobile,
  .followingDropdown {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.SkyDropDropdown {
  max-height: 500px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    width: 5px;
    border-radius: 12px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    opacity: 0.5;
    -webkit-box-shadow: inset 0 0 6px #555555;
  }
}

.HeaderDropdown {
  max-height: 500px;
  overflow-y: scroll;
}

.HeaderDropdown::-webkit-scrollbar {
  display: none;
}

.followingDropdown {
  padding: 0 10px;
  max-height: 500px;
  overflow-y: scroll;
}
.followingDropdown::-webkit-scrollbar {
  display: none;
}

.HelpButtonWrapper {
  margin-left: 15px;
  padding: 0;
  position: relative;
  display: flex;

  button {
    padding: 0;
  }
}

.HeaderDropdown,
.SkyDropDropdown,
.NotificationsDropDropdownMobile,
.followingDropdown {
  color: var(--color-dark);

  .Heroes {
    display: flex;
    align-items: center;
    padding: 15px;
    font-weight: 300;
    width: max-content;

    .HeroesLegends {
      display: flex;
      margin-left: 45px;
      width: max-content;

      .StarIcon {
        width: 13px;
        margin-right: 8px;
        fill: var(--color-yellow);
      }
    }
  }

  .Watchlist {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 15px;
    font-weight: 300;
    width: max-content;
  }
}

.HelpButton {
  display: flex;
  font-size: 13px;
  align-items: center;
  white-space: nowrap;
  font-weight: 400;

  .HelpIcon {
    margin-left: 10px;
  }

  .LightningIcon {
    margin-right: 7px;
  }

  .ScoreIcon {
    margin-right: 7px;
    width: 12px;
    height: 12px;
  }

  &.HelpButtonGreen {
    font-weight: 600;
    color: #28bb63;
  }
}

.UploadButton {
  font-size: 13px;
  padding: 7px 15px;
}

.UploadButtonMobile {
  display: none;
}

.UserDetails {
  display: flex;
  color: var(--color-gray);
  padding-left: 0px;
  padding-right: 16px;
}

.UserIcon {
  height: 25px;
  width: 25px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
}

.UserName {
  padding-left: 10px;
  font-size: 13px !important;
  white-space: nowrap;
  font-weight: 600;
}

.BusinessUsername {
  font-weight: 400;
}

.SmallUsername {
  font-size: 1rem;
}

.SmallestUsername {
  font-size: 0.75rem;
}

.Logo {
  text-decoration: none;

  svg,
  img {
    min-width: 176px;
  }
}

.LogoBusiness {
  margin-right: auto;
  object-fit: fill;
  min-width: auto !important;
  width: fit-content !important;
}

.SearchBoxWrapper,
.LogoSearchWrapper {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
}

.SeriesHeader {
  border: 1px solid var(--color-gray-light-2);
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  background-color: var(--color-white);
  height: 35px;

  .SeriesHeaderCheckbox {
    margin: 0;

    label {
      font-size: 13px;
    }
  }
}

.BusinessLogoWrapper {
  width: auto;
}

.LogoSearchWrapper {
  align-items: center;
}

.MenuButton {
  display: none;
}

.Login,
.SignUp {
  :global(.modal-content) {
    margin-top: 80px;
    background: #fff;
    background: linear-gradient(270deg, #fff 50%, #efefef 50%, #fff 100%);
  }

  @media only screen and (max-width: 1199px) {
    :global(.modal-content) {
      height: 59.26vw;
      max-height: 450px;
    }
  }
}

.SignUp {
  @media only screen and (max-width: 1199px) {
    :global(.modal-content) {
      max-height: 570px;
    }
  }
}

.SearchInfo {
  top: 5rem;
  position: absolute !important;
  bottom: auto;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  :global(.modal-content) {
    border-radius: 5px;
    padding: 1px;
    width: fit-content;
    height: fit-content;
  }
}

.SearchInfoBackdrop {
  top: 100px;
  position: absolute;
  bottom: auto;
}

.FollowingBtn {
  font-weight: normal;
  font-size: 1.3rem;
  padding-top: 13px;
}

.FollowingTooltip {
  padding: 0;
  max-width: 800px;
}

.menuItem {
  margin: 10px 0;
  color: var(--color-dark);
  font-weight: 500;
  cursor: pointer;

  a {
    color: var(--color-dark);
    text-decoration: none;
  }
}

.SocialMediaIcons {
  display: flex;
  justify-content: flex-start;
  padding: 12px 0px 0px 18px;

  .MediaIcon {
    width: 17px;
    height: 17px;
    color: var(--color-dark);
    margin-right: 7px;
  }
}

@media only screen and (max-width: 1000px) {
  .SocialMediaIcons {
    display: flex;
    justify-content: flex-start;
    padding: 0px 0px 0px 0px;
    margin-top: 15px;
    margin-bottom: 20px;

    .MediaIcon {
      width: 17px;
      height: 17px;
      color: var(--color-dark);
      margin-right: 7px;
    }
  }
}

@media only screen and (max-width: 1330px) {
  .SearchInfo {
    :global(.modal-content) {
      left: 54%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .UploadButton {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .Header {
    padding: 10px 15px 10px 15px;
    height: 70px;
    display: flex;

    &:global(.Dark) {
      background-color: var(--color-grey-light-1);
      position: fixed;
      z-index: 100;
    }
  }

  .MiniLogo {
    width: 40px;
    height: 40px;
  }

  .HeaderIos {
    padding: 40px 15px 10px 15px;
    height: 70px;
    display: flex;
    align-items: flex-end;

    &:global(.Dark) {
      background-color: var(--color-grey-light-1);
      position: fixed;
      z-index: 100;
    }
  }

  .LogoSearchWrapper {
    position: static;
  }

  .HelpWrapper {
    display: none;
  }

  .AirDropButtonMobile {
    background-color: var(--color-gray-light-1);
    padding: 0;

    .HelpButton {
      justify-content: space-between;
      width: 100%;
      color: var(--color-dark);
      font-weight: 500;
      font-size: 15px;
      position: relative;

      .HelpIcon {
        color: var(--color-brown-gray);
        width: 12px;
      }
    }

    &:focus,
    &:hover,
    &:active {
      background-color: var(--color-gray-light-1);
    }
  }

  .TeamWorkFlowMobile {
    padding: 8px 0;

    .HelpButton {
      .Badge {
        @media only screen and (max-width: 1000px) {
          top: -2px;
          right: -4px;
          padding: 1px 5px;
          font-size: 0.6rem;
        }
      }
    }
  }

  .HeaderDropdown,
  .SkyDropDropdown,
  .followingDropdown {
    width: fit-content;
    top: 0 !important;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3 !important;
  }

  @media (min-width: 762px) and (max-width: 992px) {
    .followingDropdown {
      left: 0%;
    }
  }

  .SearchBoxWrapper {
    position: static;
  }

  .SearchBoxWrapper > div {
    padding: 0;

    svg {
      max-height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .SearchInfo {
    :global(.modal-dialog) {
      position: relative;
      left: 0;
    }

    :global(.modal-content) {
      border-radius: 5px;
      padding: 1px;
      left: 0;
    }
  }

  .MenuButton {
    display: block;
    cursor: pointer;

    .BarsIcon {
      font-size: 25px;
    }
  }

  .UploadButtonMobile {
    display: block;
    text-transform: uppercase;
  }
}

.StartButton {
  font-size: 16px;
  font-weight: 600;
  padding: 8px 15px;
  height: auto;
}

@media only screen and (max-width: 380px) {
  .MiniLogo {
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 320px) {
  .HomeHeader {
    padding: 5px 10px;
    height: 45px;
  }

  .Logo {
    svg {
      min-width: 150px;
      width: 150px;
    }
  }

  .SearchInfoHome {
    top: 45px;
  }
}

.NotificationsDropDropdown {
  right: unset;
  padding: 8px;
  width: 300px;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 200px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 12px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    opacity: 0.5;
    -webkit-box-shadow: inset 0 0 6px #555555;
  }

  .NotificationItem {
    &:not(:last-child) {
      border-bottom: 1px solid #dbdbdb;
    }

    cursor: pointer;
    margin-top: 15px;
    width: 100%;
    text-align: left;

    .NotificationItem_PeaTitle {
      margin-bottom: 0;
    }

    .NotificationItem_Type {
      color: #b7b7b7;
    }

    .NotificationItem_Empty {
      color: #b7b7b7;
    }
  }
}
.NotificationsDropDropdownHeight {
  min-height: 500px;
}

.ThreeDropdownHeight {
  min-height: 260px;
}

.FourDropdownHeight {
  min-height: 340px;
}

.CenterLoader {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
}

.TeamWorkflowWrapper {
  .HelpButton {
    color: var(--color-brown-gray);
    font-size: 13px;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    margin-right: 10px;

    &:hover {
      color: var(--dark);
    }
  }
}

.NotificationsDropDropdownMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 300px;
  max-width: 95%;
  margin: 0 auto;
  overflow-y: scroll;
  top: 2%;
  left: 0;
  padding: 0 20px;

  .NotificationItem {
    &:not(:last-child) {
      border-bottom: 1px solid #dbdbdb;
    }

    cursor: pointer;
    margin-top: 15px;
    width: 100%;
    text-align: left;

    .NotificationItem_PeaTitle {
      margin-bottom: 0;
    }

    .NotificationItem_Type {
      color: #b7b7b7;
    }
    .NotificationItem_Empty {
      color: #b7b7b7;
    }
  }
}

.NotifBadge {
  top: -4px;
  right: -4px;
}
