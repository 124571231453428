.Backdrop {
  background-color: #000;
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0.5;

  @media only screen and (min-width: 1024px) {
    top: 55px;
  }

  @media only screen and (min-width: 1440px) {
    top: 65px;
  }
}
