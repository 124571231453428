.PBModal {
  :global(.modal-dialog) {
    display: flex;
    justify-content: center;
  }

  :global(.modal-content) {
    padding: 6px;
    width: fit-content;
    border-radius: 20px;
  }

  :global(.modal-body) {
    padding: 0px;
  }

  .ModalHeader {
    display: none;
  }
}

.CloseIconWrapper {
  position: absolute;
  height: 0;
  display: block;
  width: 100%;
  left: -70px;
  top: 40px;

  .CloseIcon {
    margin-top: -80px;
    margin-right: -30px;
    float: left;
    position: relative;
    font-size: 60px;
    font-weight: 200;
    color: white;
    transform: rotate(45deg);
    cursor: pointer;
    pointer-events: none;
    z-index: 2000;
  }
}

@media only screen and (max-width: 1024px) {
  .PBModal {
    & :global(.modal-dialog) {
      min-width: 80vw;
    }
  }
}

@media only screen and (max-width: 768px) {
  .PBModal {
    padding: 0 !important;

    .ModalHeader {
      display: block;
    }
  }

  .ModalBackdrop {
    display: none;
  }

  .PBModal :global(.modal-dialog) {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .PBModal :global(.modal-body) {
    padding: 25px;
  }

  .PBModal :global(.modal-content) {
    flex: 1;
    width: 100vw;
    height: calc(100vh - 72px);
    min-height: 210vh;
    top: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    border: none;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    bottom: 0 !important;
  }
}

@media only screen and (max-width: 320px) {
  .PBModalHome :global(.modal-content) {
    height: calc(100vh - 45px) !important;
  }
}
