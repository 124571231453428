.CookiesMessage {
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  background: #6b63f7;
  color: white;
  text-align: center;
  padding: 15px;
  font-weight: 300;
  font-size: 18px;

  b {
    font-weight: 700;
    padding-left: 5px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 760px) {
  .CookiesMessage {
    font-size: 15px;
    padding: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .CookiesMessage {
    font-size: 11px;
    padding: 7.5px 10px;
  }
}
