.FollowingItem {
  font-size: 15px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .IconWrapper {
    display: inline-block;
    padding: 8px;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;

    .Icon {
      height: 55px;
      width: 56px;
      border-radius: 50%;
    }
  }

  .ItemDetails {
    display: inline-block;
    padding: 0px 25px 0px 15px;
    vertical-align: middle;
    clear: both;
    flex: 1;

    .Name {
      font-weight: 700;
      cursor: pointer;

      a {
        color: var(--color-black);
      }
    }

    .Description {
      font-family: "Work Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark);
      cursor: pointer;

      a {
        color: var(--dark);
      }
    }
  }

  .ButtonWrapper {
    // float: right;
    // width: 100%;
    height: 36px;
    border-radius: 5px;
    // flex: 0 1 20%;

    button {
      font-family: "Work Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      width: 100%;
      height: 36px;
      padding: 0.5rem 1rem;
      min-width: fit-content;
    }
  }
}

.Story {
  .IconWrapper {
    padding: 0;
    border: none;

    .Icon {
      height: 55px;
      width: 56px;
      border-radius: 6px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .FollowingItem {
    padding: 10px 15px;
    display: flex;

    .IconWrapper {
      height: 60px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .FollowingItem {
    padding: 10px 15px;
    display: flex;

    .IconWrapper {
      height: 60px;
    }

    .ButtonWrapper {
      button {
        padding: 0.5rem 1rem;
      }
    }
  }
}
