.TabListItem {
  float: left;
  padding: 8px 10px;
  position: relative;
  top: 2px;
  cursor: pointer;
  margin-right: 15px;

  &:global(.active) {
    color: var(--color-black);
    font-weight: 500;
    border-bottom: 4px solid var(--color-black);
  }
}

@media only screen and (max-width: 760px) {
  .TabListItem {
    font-size: 15px;
  }
}

@media only screen and (max-width: 374px) {
  .TabListItem {
    font-size: 12px;
  }
}
