.TabContainer {
  .TabsList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  hr {
    width: 100%;
    clear: both;
  }

  .TabContent {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    clear: both;
  }
}
