.Embed {
  min-height: 400px;
  width: 100%;
  height: 100%;
  border: none;
  background: var(--color-white);
}

.Embed_Glassdoor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.GlassdoorIframeWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .Embed {
    height: 100%;
  }
}

.Crosswordlabs {
  display: flex;
}
