.WeirdGreen {
  color: var(--color-weird-green);
  text-decoration: underline;
  text-decoration-color: var(--color-weird-green);
}
      
.InfoLink,
.InfoLink a {
  color: var(--color-primary);

  a {
    text-decoration: underline;
  }
}
