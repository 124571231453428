.SearchInfoModal {
  height: 100%;
  width: 590px;
  border-radius: 5px;

  position: absolute;
  top: 100%;
  left: 5%;

  .SearchInfoTabs {
    background-color: var(--color-white);
    padding: 1.5rem 2rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
}

.BottomTermWrapper {
  width: 100%;
  background: var(--color-gray-light-1);
  border-top: 1px solid var(--color-gray-light-2);
  color: var(--color-brown-gray);
  padding: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  word-break: break-word;
}

.NoStoriesMessage {
  background: var(--color-white);
  font-size: 16px;
  text-align: center;
}

.CategoryButton {
  color: var(--color-brown-gray);
  border: 1px solid var(--color-gray-light-2);
  font-size: 14px;
  font-weight: 400;
  padding: 5px 1rem;

  &:global(.Selected) {
    color: var(--color-weird-green);
    font-weight: 500;
    border: 1px solid var(--color-weird-green);
  }
}

.CategoryList {
  padding: 0px 15px;

  .ButtonWrapper {
    display: inline-block;
    padding: 0 10px 10px 0;
  }
}

.TagList {
  padding: 0px 15px;

  .TagDetails {
    display: inline-block;
    padding: 0px 20px 8px 0px;
    font-size: 15px;
    cursor: pointer;

    .TagIcon {
      transform: scaleX(-1);
      color: var(--color-gray-light-2);
    }

    .TagName {
      padding-left: 8px;
      color: var(--color-brown-gray);
    }

    &:global(.Selected) {
      .TagIcon,
      .TagName {
        color: var(--color-weird-green);
      }
    }
  }
}

.SeriesList {
  padding: 0 15px;

  .SeriesDetails {
    display: inline-block;
    padding: 0px 20px 8px 0px;
    font-size: 15px;
    cursor: pointer;

    .SeriesIcon {
      transform: scaleX(-1);
      color: var(--color-gray-light-2);
    }

    .SeriesName {
      padding-left: 8px;
      color: var(--color-brown-gray);
    }

    &:global(.Selected) {
      .SeriesDetails,
      .SeriesIcon,
      .SeriesName {
        color: var(--color-weird-green);
      }
    }
  }
}

.DesiredTag, .SearchTag{
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.ChannelList {
  padding: 0;
  position: relative;
  bottom: 5px;
  max-height: 22rem;
  overflow: auto;

  li {
    list-style: none;
    border-bottom: 1px solid var(--color-gray-light-2);
  }
}

@media only screen and (max-width: 768px) {
  .SearchInfoModal {
    width: 100%;
    padding: 0 15px;
    left: 0;
    top: 70px;
    height: 100vh;

    border-top-left-radius: 5px;

    .SearchInfoTabs {
      height: 100%;
      padding: 20px;
    }
  }

  .BottomTermWrapper {
    position: absolute;
    bottom: 70px;
  }

  .ChannelList {
    padding: 0;
    position: relative;
    bottom: 5px;
    max-height: calc(100% - 62px);
    overflow: auto;
    margin-bottom: -6px;

    li {
      list-style: none;
      border-bottom: 1px solid var(--color-gray-light-2);
    }
  }
}
