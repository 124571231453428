.Accordion {
  margin: 10px 0;

  .TitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Title {
      font-size: 15px;
      margin: 0;
    }

    .ArrowIcon {
      width: 12px;
    }
  }

  ul.AccordionItems {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    margin: 0;
    padding: 0;

    li.AccordionItem {
      list-style-type: none;

      .Link {
        display: flex;
        align-items: center;

        .Icon {
          margin-right: 15px;
        }
      }
    }
  }
}
