@function get-vw($target) {
  $vw-context: (1141 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}

.grid {
  max-width: calc(100vw - 20vw);
  // left: 50%;
  // transform: translate(-50%);
  margin: 0 auto !important;

  @media (max-width: 1140px) {
    max-width: calc(100vw - 40px);
  }

  @media (max-width: 375px) {
    max-width: calc(100vw - 9vw);
  }
}

.grid,
.gridPeaVideoPage,
.CompanyGallery {
  height: 100%;
  margin: 0 auto;

  .gridItem {
    width: get-vw(220px);
    margin-bottom: 20px;

    &.gridItemModal {
      width: get-vw(213px);
    }

    .VideoContainer {
      width: get-vw(220px);

      .videoDescription {
        display: flex;

        .VideoDataWrapper {
          display: flex;
          align-items: center;

          h1 {
            flex: 1;
            white-space: pre-line;
            font-family: "Work Sans", sans-serif;
            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--greyish-brown);
            margin-bottom: 20px;
          }

          .VideoDataIcon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          .VideoDataTitleWrapper {
            flex: 4;
            white-space: pre-line;
            font-family: "Work Sans", sans-serif;
            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--greyish-brown);
          }
        }

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    .PeaVideoPageContainer {
      .VideoDataWrapper {
        display: none;
      }

      .videoDescription {
        display: none;
      }
    }
  }

  .swipeIcon {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
  }
}

.RecommendationBox {
  margin: 11px;
  height: 285px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .UserName {
    font-size: 17px;
    font-weight: 700;
  }

  .ButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}

.RecommendationInfoMobile {
  display: none;
}

.noVideoText {
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0px;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  color: #2f2e41;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

// MEDIA
@media (min-width: 1200px) {
  :global(.container) {
    max-width: 1590px;
  }

  :global(.carousel) {
    flex: 1;
  }
}

@media (min-width: 1141px) {
  .CompanyGallery {
    .VideoContainer {
      width: get-vw(220px) !important;
    }
  }
}

@media screen and (max-width: 1140px) and (min-width: 576px) {
  .gridItemModal {
    width: 355px !important;
  }

  .grid,
  .CompanyGallery {
    .gridItem {
      width: 355px;
    }
  }

  .Square {
    height: 355px;
    width: 355px;
  }

  .Rectangle {
    height: 483px;
    width: 355px;
  }
}

@media screen and (max-width: 1140px) {
  .grid,
  .CompanyGallery {
    .gridItem {
      width: calc((100vw - 120px) / 3);
      .VideoContainer {
        width: 100%;
      }
    }
  }

  .Square {
    height: 255px;
    width: 100%;
  }

  .Rectangle {
    height: 400px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .grid,
  .gridPeaVideoPage,
  .CompanyGallery {
    .gridItem {
      width: 100%;
      &.gridItemModal {
        padding-right: 15px;
        padding-left: 15px;
        width: 100% !important;
      }
    }
  }

  .grid .gridItem .VideoContainer {
    width: 100%;
  }

  .VideoContainer,
  .PeaVideoPageMobile {
    width: 100%;

    .Rectangle {
      height: 439px !important;
      width: 100%;
    }

    .Square {
      height: 314px;
      width: 100%;
    }

    .VideoDataWrapper {
      display: none;
    }

    .videoDescription {
      display: none;
    }
  }

  .RecommendationInfoMobile {
    margin: 11px 0;
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .RecommendationBox {
    height: auto;
    margin: 11px 0px;
    flex-direction: unset;
    justify-content: space-around;

    p {
      margin-bottom: 0;
      font-size: 17px;
      margin: 10px 0;
    }

    .ButtonWrapper {
      justify-content: center;
      margin-top: 0;

      a {
        button {
          font-size: 13px !important;
          border: 1px solid var(--color-cornflower);
        }
      }
    }
  }

  .RecommendationInfo {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .VideoContainer,
  .PeaVideoPageMobile {
    width: 300px !important;
    margin: 0 auto;

    .Rectangle {
      height: 425px !important;
      width: 300px !important;
    }

    .Square {
      height: 300px;
      width: 300px;
    }
  }
}
