.EpisodeContainer {
  position: absolute !important;
  z-index: 999;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  opacity: 0;

  &.Controls {
    opacity: 1;
    transition: 0.3s all ease-in-out;
  }

  .Episode__control {
    border-radius: 5px;
    opacity: 0.75;
    color: var(--color-dark);
    border: 0;
    min-width: 110px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    z-index: 1000;

    .Episode__indicators {
      padding: 0;

      .Episode__indicator {
        padding: 0 4px 0 0;
      }

      svg {
        width: 10px;
        fill: var(--color-black);
      }
    }
  }

  .Episode__menu {
    z-index: 1000;
    .Episode__menu-list {
      .Episode__option {
        font-size: 12px;
      }
    }
  }
}
