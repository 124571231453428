@function get-vw($target) {
  $vw-context: (1141 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}

.CompanyLink {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.PeaVideoModal {
  backdrop-filter: blur(5px);
  padding-top: 40px;

  :global(.modal-content) {
    border-radius: 20px;
    border: none;
  }

  :global(.modal-body) {
    padding: 0;
  }

  :global(.modal-90w) {
    max-width: 63% !important;
    margin: 1.75rem auto;
    border-radius: 20px;
  }
}

.commentScenter {
  display: flex;
  height: '100%';
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  text-align: center;
}
.VideoWrapperVideoPage {
  margin-top: 30px;

  .VideoWrapper {
    position: relative;
    margin: 0 auto;

    @media only screen and (max-width: 992px) {
      border-radius: 0 0 40px 40px;
      overflow: hidden;
    }

    .VideoPlayer {
      object-fit: cover;
      border-radius: 20px;

      @media only screen and (max-width: 992px) {
        border-radius: 0;
      }

      &:focus {
        outline: none;
      }
    }

    .VideoEmbed {
      border-radius: 20px;
      position: absolute;
      z-index: 1;
      height: 100% !important;
      width: 100% !important;

      top: 49.9%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .ChevronLeft,
    .ChevronRight {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
      
      opacity: 0;
    }

    .ChevronLeft {
      left: 10px;
    }

    .ChevronRight {
      right: 10px;
    }

    .Controls {
      opacity: 1;
      transition: 0.3s all ease-in-out;
    }
  }
}

.TitleTabsWrapperPeaVideoPage {
  margin-top: 30px;

  .PeaVideoTitle {
    margin-top: 35px;
    margin-bottom: 40px;
    width: 100%;
    height: fit-content;
    font-family: 'Work Sans', sans-serif;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    // -webkit-hyphens: auto;
    // hyphens: auto;
    p {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      // -ms-hyphens: auto;
      // -moz-hyphens: auto;
      // -webkit-hyphens: auto;
      // hyphens: auto;
    }
  }

  .MenuTabsPeaVideoPage {
    border-bottom: 1px solid var(--brown-grey);

    a {
      border: none;
      font-family: 'Work Sans', sans-serif;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--brown-grey);
      border-bottom: 1px solid var(--brown-grey);
      padding: 0.5rem;
      top: 0 !important;

      &:hover {
        border-bottom: 1px solid var(--brown-grey);
        top: 0 !important;
      }

      &:last-child {
        font-weight: 600;
      }
    }

    :global(a.active) {
      border: none;
      font-family: 'Work Sans', sans-serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark);
      border-bottom: 2px solid var(--dark);
      top: 0 !important;
    }
  }

  :global(.tab-content) {
    margin-top: 25px;
    font-family: 'Work Sans', sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--greyish-brown);
    overflow: hidden;
    overflow-x: hidden;
  }

  .ShareButtons {
    margin-bottom: 10px;

    svg {
      width: 21px;
      height: 21px;
      font-size: 21px;
      margin-right: 2px;
    }
  }

  .CommentsAvatar {
    min-width: 30px;

    img {
      width: 30px;
      height: 30px;
      float: right;
    }
  }

  .Comment {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--brown-grey);
  }

  .CommentsForm {
    margin-top: 40px;
  }
}

.CommentFormControl {
  &:hover,
  &:focus,
  &:active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }
}

.TitleTabsWrapperPeaVideoPageMobile {
  margin-top: 30px;
  text-align: center;

  .PeaVideoTitle {
    margin-bottom: 40px;
    width: 100%;
    height: fit-content;
    font-family: 'Work Sans', sans-serif;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    p {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
    }
  }

  .MenuTabsPeaVideoPage {
    border-bottom: 1px solid var(--brown-grey);

    a {
      border: none;
      font-family: 'Work Sans', sans-serif;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--brown-grey);
      border-bottom: 1px solid var(--brown-grey);
      padding: 0.5rem;

      &:hover {
        border-bottom: 1px solid var(--brown-grey);
      }
    }

    :global(a.active) {
      border: none;
      font-family: 'Work Sans', sans-serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark);
      border-bottom: 2px solid var(--dark);
    }
  }

  :global(.tab-content) {
    margin-top: 25px;
    font-family: 'Work Sans', sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--greyish-brown);
    overflow: hidden;
    overflow-x: hidden;
  }

  .ShareButtons {
    margin-bottom: 10px;

    svg {
      width: 21px;
      height: 21px;
      font-size: 21px;
      margin-right: 2px;
    }
  }

  .CommentsAvatar {
    min-width: 30px;

    img {
      width: 30px;
      height: 30px;
      float: right;
    }
  }

  .Comment {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--brown-grey);
    text-align: left;
  }

  .CommentsForm {
    margin-top: 40px;
  }
}

.CloseIconWrapper {
  position: absolute;
  height: 0;
  display: block;
  width: 100%;

  .CloseIcon {
    left: -60px;
    margin-top: -30px;
    cursor: pointer;
    margin-right: -30px;
    float: left;
    position: relative;
    font-size: 60px;
    font-weight: 200;
    color: white;
    transform: rotate(45deg);
    pointer-events: none;
    z-index: 2000;
  }
}

.RelatedPeas {
  margin: 50px 0;

  .RelatedTitile {
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    margin-bottom: 20px;
  }
}

.BlurPeasRelated {
  height: 140px;
  padding-top: 35px;
  margin-top: -75px;
  z-index: 2;
  bottom: 0;
  text-align: center;
  width: 100%;
  background-image: url('../../icons/gradient_effect.png');
  border-radius: 30px;
}

.BlurWrapper {
  padding-top: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 30px;
}

.LoadMoreButton {
  width: 105px;
  height: 30px;
  border-radius: 5px;
  margin-top: 20px;
  border: solid 1px var(--brown-grey);
  background-color: transparent;
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--brown-grey);
}

.CompanyHeader {
  padding-top: 30px;
  font-family: 'Work Sans', sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
  display: flex;
  justify-content: space-between;

  img {
    height: 50px;
    margin-right: 15px;
  }

  .FollowButtonWrapper {
    width: 92px;
    height: 36px;
    border-radius: 5px;

    button {
      // float: right;
      font-family: 'Work Sans', sans-serif;
      width: 92px;
      height: 36px;
      padding: 0.5rem 1rem;
      font-size: 15px;
    }
  }
}

.PopoverShareButtons {
  top: 10px !important;

  button {
    margin-bottom: 10px;
  }
}

.infoIcon {
  margin-right: 5px;
}

.mobileTab {
  width: fit-content;
}

.hiddenTab {
  display: none;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.WhiteSeparator_first,
.WhiteSeparator_second {
  color: transparent;
}

.alignLeft {
  text-align: left !important;
  padding-left: 0;
}

.publishedDate {
  margin-bottom: 15px;

  .InfoRow1 {
    margin-bottom: 10px;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  .buttonTab {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: solid 1px var(--brown-grey);
    font-family: 'Work Sans', sans-serif;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--brown-grey);
    margin: 5px 5px;
  }

  .buttonTabShare {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border-color: var(--brown-grey) !important;
    border: solid 1px var(--brown-grey) !important;
    font-family: 'Work Sans', sans-serif;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--brown-grey) !important;
    margin: 5px 5px;
    background-color: white !important;
  }

  .buttonTabActive {
    background-color: var(--cornflower);
    color: white;
    font-weight: bold;
  }
}

.likesText {
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
}

.noPaddingRight {
  padding-right: 0;
}

.noFlex {
  a {
    flex: unset !important;
  }
}

.Square {
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 100%;

  iframe,
  video {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    padding: 0;
  }
}

.Rectangle {
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 125%;

  iframe,
  video {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    padding: 0;
  }
}

.fullscreenBtn {
  color: #000;
  margin: auto 6px;
  background-color: transparent;
  border-radius: 2px;
  border: 0;
  position: absolute;
  bottom: 42px;
  font-size: 15px;
  right: 120px;
}

.fullscreenBtnColorWhite {
  color: white !important;
}

.fullscreenBtnColorTransparent {
  color: transparent !important;
}

.CommentsList {
  max-height: 280px;
  overflow: auto;
}

.CommentsWrapper {
  height: 100%;
}

.CommentWrapper {
  margin: 5px 0;
}

.HeartIcon {
  background-color: white;
  border-radius: 5px;
  font-size: 30px;
  padding: 8px;
  opacity: 0.77;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 12px;
  transition: opacity, background-color 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  &:global(.active) {
    opacity: 0.9;
    color: var(--color-primary);
  }
}

.DesktopInfoTab {
  display: flex;

  section {
    flex: unset;
  }

  .peaViews {
    flex: 1;
    width: auto;
    text-align: center;
  }

  .likesText {
    flex: unset;
    min-width: fit-content;
    margin-right: 20px;
  }
}

.buttonTabAddToWatchList {
  width: auto !important;
  height: 30px;
  border-radius: 5px;
  border: solid 1px var(--brown-grey);
  font-family: 'Work Sans', sans-serif;
  font-size: 11px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--brown-grey);
  margin: 5px 5px;

  svg {
    margin-right: 5px;
  }
}

@media screen and (max-width: 1141px) {
  .RelatedPeas {
    margin: 50px 0;
    overflow-x: hidden;

    & > div {
      padding: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  .VideoWrapperVideoPage {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .TitleTabsWrapperPeaVideoPage {
    flex: 0 0 55%;
    max-width: 55%;
  }
}

@media screen and (max-width: 1198px) and (min-width: 992px) {
  .VideoWrapperVideoPage {
    text-align: center;
    margin: 0 auto;
  }
  .TitleTabsWrapperPeaVideoPage {
    padding-right: 3rem;
  }

  .fullscreenBtn {
    color: #000;
    margin: auto 6px;
    background-color: transparent;
    border-radius: 2px;
    border: 0;
    position: relative;
    bottom: 42px;
    font-size: 15px;
    right: 140px;
  }
}

@media screen and (max-width: 991px) and (min-width: 576px) {
  .fullscreenBtn {
    color: #000;
    margin: auto 6px;
    background-color: transparent;
    border-radius: 2px;
    border: 0;
    position: relative;
    bottom: 42px;
    font-size: 15px;
    right: 140px;
  }
}

@media only screen and (max-width: 991px) {
  .VideoWrapperVideoPage {
    text-align: center;
    margin: 0 auto;
  }
  .TitleTabsWrapperPeaVideoPage {
    padding-right: 0;
  }
}

@media only screen and (max-width: 760px) {
  .CompanyHeader {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .CommentWrapper {
    padding: 0 48px;
  }

  .CommentsAvatar {
    padding: 0;
  }

  .CommentsForm {
    padding: 0 32px;
    align-items: center;
    overflow-x: hidden;
  }

  .VideoEmbed {
    margin: 0 5%;
    left: 0;
    padding-right: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 992px) {
  time::after {
    content: '\A\A';
    white-space: pre;
  }

  .WhiteSeparator_first {
    display: none;
  }

  .CompanyHeader {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px !important;

    img {
      width: 100%;
      margin-left: 15px !important;
      margin-right: 15px !important;
      border: solid 1px #efefef;
    }

    .FollowButtonWrapper {
      button {
        font-size: 13px !important;
      }
    }
  }

  .VideoWrapperVideoPage {
    margin-top: 0 !important;
    padding: 0 !important;
  }

  .buttonsWrapper {
    .buttonTab {
      display: flex;
      align-items: center;
      width: fit-content !important;
      font-size: 13px !important;
      padding: 0 16px;
    }

    .buttonTabShare {
      display: flex;
      align-items: center;
      width: fit-content !important;
      font-size: 13px !important;
    }

    .buttonTabAddToWatchList {
      display: flex;
      align-items: center;
      width: fit-content !important;
      font-size: 12px !important;
      padding: 0 16px;
    }
  }

  .popoverBody {
    width: 245px !important;
    padding: 0 13px 13px 13px;
  }

  .RelatedPeas {
    .RelatedTitile {
      font-size: 15px;
    }
  }

  .VideoPlayer {
    border-radius: 5px;
  }
}

@media only screen and (min-width: 576px) {
  .VideoPlayer {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 575px) {
  time::after {
    content: '\A\A';
    white-space: pre;
  }

  .WhiteSeparator_first {
    display: none;
  }

  .PeaVideoTitle {
    font-size: 20px !important;
    text-align: center;
    margin-bottom: 20px !important;
  }

  .CompanyHeader {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px !important;

    img {
      width: 100%;
      margin-left: 15px !important;
      margin-right: 15px !important;
      border: solid 1px #efefef;
    }

    .FollowButtonWrapper {
      button {
        font-size: 13px !important;
      }
    }
  }

  .VideoWrapperVideoPage {
    margin-top: 0 !important;
    padding: 0 !important;
  }

  .buttonsWrapper {
    .buttonTab {
      display: flex;
      align-items: center;
      width: fit-content !important;
      font-size: 13px !important;
      padding: 0 16px;
    }

    .buttonTabShare {
      display: flex;
      align-items: center;
      width: fit-content !important;
      font-size: 13px !important;
    }

    .buttonTabAddToWatchList {
      display: flex;
      align-items: center;
      width: fit-content !important;
      font-size: 12px !important;
      padding: 0 16px;
    }
  }

  .popoverBody {
    width: 245px !important;
    padding: 0 13px 13px 13px;
  }

  .RelatedPeas {
    .RelatedTitile {
      font-size: 15px;
    }
  }
}
