.TooltipWrapper {
  position: relative;
  background: var(--color-transparent);
  margin-right: -9px;

  .Tooltip {
    position: absolute;
    min-width: 210px;
    padding: 11px;
    background: var(--color-white);
    z-index: 999;
    border-radius: 8px;

    &.OverlappingTooltip {
      left: auto !important;
      right: 20px !important;
    }

    .Text {
      text-align: center;
      font-size: 12px;
      color: var(--color-dark);
      margin: 0;
      font-weight: 300;
      line-height: 1;

      b {
        font-weight: 600;
      }
    }
  }

  .InfoLink,
  .InfoLink a {
    color: var(--color-primary);

    a {
      text-decoration: underline;
    }
  }
}
