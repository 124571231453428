//@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=block');
* {
  font-family:  "Work Sans", sans-serif;
}

body {
  &:global(.modal-open) {
    #root {
      height: 100vh;
    }
  }
}

.modal-open{
  &:before{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
    content: "";
    z-index: 10;
  }
}

@media only screen and (max-width: 768px) {
  body {
    &:global(.modal-open) {
      #root {
        overflow: hidden;
      }
    }
  }
}

:global {
  .modal {
    position: absolute !important;
    top: 0;
    bottom: auto;
    right: 0;
    left: 0;
  }
}

html, body, .modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto !important;
}


.popover{
  z-index: 2001;
}

.text-blue{
  color: #0d6efd !important;
}

input.text-blue{
  &:checked {
    + p {
      &:before {
        background-color: #0d6efd !important;
      }
    }
  }
}
//* {
//  font-family:  "Work Sans Regular", sans-serif;
//}
.modal{
  &.show{
    display: block !important;
  }
}


span {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;

}

p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;

}

h1 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;

}

h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;

}

h3 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;

}

h4 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;

}
