.VideoPlayer {
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.grid {
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .gridItem {
    min-width: 220px;
    /* min-width: 314px; */
    //width: 22.12vw;
    width: 19.29vw;
    /* width: 314px; */
  }

  .swipeIcon {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
  }
}

.gridPeaVideoPage {
  width: 100% !important;
  height: 100%;
  margin: 0 auto;

  .gridItem {
    //width: 245px;
    //width: 314px;
    min-width: 220px;
    width: 19.29vw;
    //min-width: 314px;
    //width: 22.12vw;
  }

  .swipeIcon {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
  }
}

.PeaVideoPageMobile {
  /* width: 314px; */

  .VideoDataWrapper {
    display: none;
  }

  .videoDescription {
    display: none;
  }
}

.PeaVideoPageContainer {
  min-width: 220px;
  width: 19.29vw;

  .VideoDataWrapper {
    display: none;
  }

  .videoDescription {
    display: none;
  }
}

.VideoContainer {
  min-width: 220px;
  width: 19.29vw;
  /* width: 314px; */

  .VideoDataWrapper {
    display: flex;
    align-items: flex-start;

    .VideoDataIcon {
      width: 30px;
      height: 30px;
    }

    .VideoDataTitleWrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

      .VideoMetaTitle {
        white-space: pre-line;
        font-family: "Work Sans", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--greyish-brown);
        margin-bottom: 0;
      }
    }
  }

  .videoDescription {
    display: flex;

    svg {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }

    h1 {
      flex: 1;
      white-space: pre-line;
      font-family: "Work Sans", sans-serif;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--greyish-brown);
      margin-bottom: 20px;
    }
  }
}

.Video {
  padding: 2rem;
  border-radius: 40px;
  box-sizing: border-box;
  position: relative;
}

.RecommendationBox {
  margin: 11px;
  height: 285px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: column;

  .UserName {
    font-weight: 700;
  }

  .ButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    a.LinkWrapper {
      text-decoration: none !important;
    }
  }

  .LinkWrapper {
    text-decoration: none !important;
  }
}

:global(.col-xl-3) {
  padding: 0 !important;
}

// MEDIA
@media (min-width: 1200px) {
  :global(.container) {
    max-width: 1590px;
  }

  :global(.carousel) {
    flex: 1;
  }
}

@media screen and (max-width: 1198px) and (min-width: 992px) {
}

@media screen and (max-width: 991px) and (min-width: 576px) {
  .Square {
    min-height: 220px;
    min-width: 220px;
    height: 19.29vw;
    width: 19.29vw;
  }

  .Rectangle {
    min-height: 340px;
    min-width: 220px;
    height: 29.81vw;
    width: 19.29vw;
  }
}

@media (max-width: 767px) {
  .infiniteScrollWrapper {
    .grid {
      padding: 30px 15px;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 575px) {
  .VideoPlayer {
    .grid {
      .gridItem {
        min-width: 314px !important;
      }
    }
  }
}
