.SignIn {
  width: 660px;
  height: auto;
  text-align: center;
  display: flex;
}

.FormFields {
  font-size: 13px;
}

.SignIn__Info {
  width: 50%;
  font-size: 1.32vw;
  color: #2f2e41;
  padding-right: 15px;
  text-align: center;

  .title,
  .undertext,
  .normal {
    font-family: "Work Sans", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    font-weight: 600;
  }

  .normal {
    font-weight: 400;
  }

  .undertext {
    display: block;
    font-weight: 400;
  }
}

.SignIn__Form {
  width: 50%;
  padding: 0 0 0 40px;
  text-align: center;

  input {
    font-size: 13px;
    width: 100%;
    height: 30px;
  }
  button {
    font-size: 13px;
    width: 100%;
    height: 30px;
    padding: 4px;
    font-weight: 500;
  }

  .SignIn__Form--Image {
    margin-bottom: 50px;

    .ImageLogo {
      min-width: 176px;
    }
  }

  :global {
    .alert-danger {
      font-size: 11px;
      word-break: break-all;
    }
  }

  .ErrorMessage {
    color: var(--color-danger);
    text-align: left;
    font-size: 0.7rem;
    margin-bottom: 15px;
    margin-top: -12px;
  }
  .ErrorMessage__Center {
    color: var(--color-danger);
    text-align: center;
    font-size: 0.7rem;
    margin-bottom: 15px;
  }
}

.SignIn__Form-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 0.75rem;
}

.CreateAccountText {
  text-align: center;
  font-size: 11px;
  color: var(--color-grey-dark-3);
  z-index: 2;

  a,
  b {
    display: inline-block;
    padding-left: 5px;
    color: var(--color-grey-dark-3);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footerImageMobile {
  display: flex;
  justify-content: center;
  width: 100%;
}

.SideImageLogo-mobile {
  width: 30%;
}

.LinkedinButtonWrapper {
  position: relative;
  padding: 0.88vw 0;
}

.ForgotPassword {
  margin-top: -0.44vw;
  margin-bottom: 0.88vw;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-grey-dark-2);
  text-align: left;

  font-size: 0.79vw;

  &:hover {
    text-decoration: underline;
  }
}

.LinkedInWrapper{
  display: flex;
  vertical-align: baseline;
  column-gap: 11px;
}

@media only screen and (max-width: 1199px) {
  .SignIn {
    padding: 60px 45px 20px 30px;
    max-height: 555px;
  }

  .SignIn__Info {
    padding: 0;

    section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title,
      .undertext {
        padding: 0;
      }

      b,
      p {
        font-family: "Work Sans", sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--dark);
      }
    }
  }

  .SignIn__Form {
    padding: 0 0 0 45px;

    :global {
      .alert-danger {
        white-space: pre-line;
        word-break: break-word;
      }
    }

    fieldset:first-child {
      margin-bottom: 10px;
    }

    fieldset > div:not(.ForgotPassword) {
      margin-bottom: 0;
    }

    input {
      font-family: "Work Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--brown-grey);
      border-radius: 5px;
      border: solid 1px var(--very-light-pink);
    }

    form {
      button {
        border-radius: 5px;
        background-color: var(--cornflower);
        font-family: "Work Sans", sans-serif;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--white);
        margin-bottom: 10px;
        padding: 4px;
      }
    }

    .LinkedinButtonWrapper {
      padding: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;

        svg {
          margin-right: 15px;
        }

        div {
          position: initial !important;
          font-family: "Work Sans", sans-serif;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--greyish-brown);
        }
      }
    }
  }

  .ForgotPassword {
    margin-top: 5px;
    margin-bottom: 10px;
    font-family: "Work Sans", sans-serif;
    font-size: 9px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--brown-grey);
  }

  .CreateAccountText {
    margin-top: 10px;
    font-family: "Work Sans", sans-serif;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--greyish-brown);
  }
}

@media only screen and (max-width: 760px) {
  .SignIn {
    width: 100%;
    min-width: unset;
    min-height: unset;
    display: flex;
    flex-direction: column;

    nav {
      box-shadow: 0 1px 4px 0 rgba(142, 142, 142, 0.48);
      background-color: var(--white);
    }
  }

  .SignIn__Info {
    padding-top: 50px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .undertext {
      padding-left: 0;
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      height: 18px;
      font-family: "Work Sans", sans-serif;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--dark);
      margin-bottom: 20px;
    }
  }

  .SignIn__Form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 0 40px;

    input {
      font-family: "Work Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--brown-grey);
      border-radius: 5px;
      border: 1px solid var(--very-light-pink);
      margin-bottom: 10px;
    }

    form {
      button {
        border-radius: 5px;
        background-color: var(--cornflower);
        font-family: "Work Sans", sans-serif;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--white);
        margin-bottom: 10px;
      }
    }

    .LinkedinButtonWrapper {
      padding: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        img {
          margin-right: 15px;
          background-color: var(--greyish-brown);
        }

        div {
          position: initial !important;
          font-family: "Work Sans", sans-serif;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--greyish-brown);
        }
      }
    }

    .ForgotPassword {
      width: 125px;
      height: 13px;
      font-family: "Work Sans", sans-serif;
      font-size: 11px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--brown-grey);
      margin-bottom: 20px;
    }

    .CreateAccountText {
      margin-top: 10px;
      font-family: "Work Sans", sans-serif;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--greyish-brown);
      z-index: 2;
    }
  }

  .footerImageMobile {
    margin-top: -33px;
  }
}

@media only screen and (max-width: 760px) {
  .SignIn {
    padding: 0;
    height: auto;
    overflow-y: auto;
    -webkit-overflow-y: auto;
  }

  .footerImageWrapper {
    display: flex;
    justify-content: center;
    margin: 0px auto;
    width: fit-content;
  }

  .footerImageMobile {
    width: 70%;
    height: auto;
    margin: 0 auto;
    margin-top: -33px;
  }

  .footerImageMobileSafari {
    width: 80vw;
    height: 30vh;
    margin: 0 auto;
  }

  .PeaLogoLetterIn {
    width: 10rem;
    height: auto;
    margin: 0 auto;
  }

  .PeaLogoLetterInSafari {
    width: 10rem;
    height: auto;
    margin: 0 auto;
  }
}

@media (min-width: 1199px) {
  .SignIn {
    padding: 60px 60px 15px 50px;
    max-height: fit-content;

    .SignIn__Info {
      section {
        b,
        p {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 760px){
  .SignIn {
    background: no-repeat center url("../../../icons/login_cover.png") ;
    background-position-y: 7px;
    background-position-x: 12px;
    background-size: 100%;
  }
}


@media only screen and (max-width: 320px) {
  .PeaLogoLetterIn {
    width: 8rem;
    height: auto;
    margin: 0 auto;
  }

  .PeaLogoLetterInSafari {
    width: 8rem;
    height: auto;
    margin: 0 auto;
  }
}
