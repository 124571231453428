.Filter {
  display: inline-block;
  // width: 44vw;
  max-width: 550px;
  padding: 1rem;
  padding-top: unset;
  text-align: center;

  button {
    display: inline-block;
    width: fit-content;
    height: 30px;
    padding: 5px 10px;
    font-weight: 400;
    line-height: 15px;
    margin: 10px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1199px) {
  .HomeTitle {
    padding: 0;
  }
}

@media only screen and (max-width: 760px) {
  .Filter {
    width: 100%;
    max-width: 100%;

    button {
      margin: unset;
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .Filter {
    button {
      margin-right: 8px;
      margin-bottom: 8px;
      font-size: 11px;
    }
  }
}
