.Button {
  text-decoration: none;
  background-color: transparent;
  border-radius: 5px;
  border: none;
  color: var(--color-black);
  outline: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  font-weight: 500;

  &:hover {
    color: var(--color-grey-dark-3);
    background-color: white;
  }

  &:disabled {
    color: var(--color-grey-dark-1);
    cursor: not-allowed;
  }
}

.Blue {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-white);
  min-height: 30px;
  font-size: 13px;
  font-weight: normal;
  border-radius: 5px;
  padding: 0;

  &:hover {
    color: var(--color-white);
    background-color: var(--color-primary-dark);
  }

  &:disabled {
    color: var(--color-brown-gray);
    background: var(--color-grey-light-1);
    border: none;
  }
}

.Success {
  background-color: var(--color-weird-green);
  border: 1px solid var(--color-weird-green);
  color: var(--color-white);

  min-height: 30px;
  font-size: 13px;
  font-weight: normal;
  border-radius: 5px;
  padding: 0;

  &:hover {
    color: var(--color-white);
    background-color: var(--color-secondary-dark);
  }

  &:disabled {
    background-color: var(--color-gray-light-2);
    border-color: var(--color-gray-light-2);
    color: var(--color-brown-gray);
  }
}

.Following {
  background-color: var(--color-weird-green);
  border: 1px solid var(--color-weird-green);
  color: var(--color-white);
  // color: var(--color-weird-green);
  padding: 10px 15px;

  &:hover {
    color: var(--color-white);
    background-color: var(--color-secondary-dark);
  }
}

.Follow {
  background-color: var(--color-white);
  border: 1px solid var(--color-weird-green);
  color: var(--color-weird-green);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-weird-green);
  }
}

.Linkedin {
  background-color: var(--color-grey-light-2);
  border: 1px solid var(--color-grey-light-2);
  color: var(--color-grey-dark-3);
  margin-top: 0rem;

  min-height: 30px;
  font-size: 13px;
  font-weight: normal;
  border-radius: 5px;
  padding: 0;
  padding-left: 20px;

  position: relative;
  margin: 10px 0;

  &:hover {
    color: var(--color-grey-dark-3);
    background-color: var(--color-grey-light-3);
    border: 1px solid var(--color-grey-light-3);
  }

  div:first-child {
    top: 4px;
  }
}

.SignIn,
.SignUp {
  width: 80px;
  height: 40px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 5px;
  padding: 0.5rem;
  margin-left: 10px;
  margin-right: 10px;
}

.SignUp {
  background-color: var(--color-weird-green);
  border: 1px solid var(--color-weird-green);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-secondary-dark);
    border: 1px solid var(--color-secondary-dark);
    color: var(--color-white);
  }
}

.Header {
  color: var(--color-brown-gray);
  align-items: center;
  padding-left: 0px;

  &:hover {
    color: var(--color-grey-dark-1);
  }
}

.FilterHome {
  margin: 1rem;
  width: 5rem;
  background-color: transparent;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  font-weight: normal;

  &:hover {
    background-color: transparent;
  }
}

.Company {
  width: fit-content;
  background-color: transparent;
  border: 1px solid var(--color-gray-light-2);
  color: var(--color-brown-gray);
  font-weight: 300;
  font-size: 14px;
  margin-right: 30px;
  flex: 0 1 auto;
  margin-top: 10px;
  padding: 0.45rem 1rem;

  &:hover {
    background-color: var(--color-grey-light-1);
  }
}

.Selected {
  background-color: var(--color-grey-light-3);
  color: var(--color-primary-dark);
  font-weight: bold;
}

.Highlighted {
  font-weight: bold;
  border: solid 1px var(--cornflower);
  color: var(--cornflower);
}

button:focus {
  outline: 0;
}

.Recommendation {
  width: fit-content;
  color: var(--color-cornflower);
  background-color: var(--color-white);
  border: 1.3px solid var(--color-cornflower);
  font-size: 17px;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    color: var(--color-white);
    background-color: var(--color-cornflower);
  }
}

.Danger {
  background: var(--color-danger);
  color: #fff;
  border: 1px solid var(--color-danger);
  width: fit-content;
  padding: 13px 15px;
  transition: all 0.3s ease;
  font-size: 13px;

  &:hover {
    border: 1px solid var(--red);
    color: #fff;
    background: var(--red);
  }
}

.GrayLight {
  background: var(--color-grey-light-1);
  color: var(--color-brown-gray);
  border: 1px solid var(--color-grey-light-1);
  width: fit-content;
  padding: 13px 15px;
  font-size: 13px;

  &:hover {
    border: 1px solid var(--color-brown-gray);
    color: var(--color-brown-gray);
    background: var(--white);
  }
}

.GrayLightBorder {
  background: var(--color-white);
  border: 1px solid var(--color-gray-light-2);
  color: var(--color-gray-light-2);

  &:hover {
    color: var(--color-dark);
    border: 1px solid var(--color-dark);
  }
}

.BrownGrayBorder {
  background: var(--color-white);
  border: 1px solid var(--color-gray-light-2);
  color: var(--color-brown-gray);
}

.WhiteShadow {
  background: var(--color-white);
  color: var(--color-dark);
  border: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
}

.Transparent {
  color: var(--color-brown-gray);
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Header {
    padding: 10px 10px 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .Success {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    min-height: 35px;
  }

  .Company {
    width: auto;
    margin: 5px;
  }
}
