.FormGroup {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  &.InputError {
    input,
    textarea {
      border-color: var(--color-danger) !important;
    }
  }

  label {
    font-size: 13px;
    font-weight: 400 !important;
    margin-bottom: 10px;
    color: var(--color-dark);
  }

  input[type="radio"],
  input,
  select,
  textarea {
    padding: 8px 10px;

    &::-webkit-input-placeholder {
      color: var(--color-brown-gray);
    }

    &:-ms-input-placeholder {
      color: var(--color-brown-gray);
    }

    &::placeholder {
      color: var(--color-brown-gray);
    }

    &:disabled {
      background-color: var(--color-gray-light-2);
      color: var(--color-gray-light-2);
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .ErrorMessage {
    display: block !important;
    color: var(--color-danger);
    font-size: 0.7rem;
    margin-top: 5px;
    text-align: left;
  }

  .SubLabel {
    color: var(--color-brown-gray);
    font-size: 13px;
  }
}

.Input {
  input {
    border: 1px solid var(--color-gray-light-2);
    border-radius: 5px;
    min-height: 30px;
  }
}

.FileInput {
  position: relative;
  align-items: center;
  margin: 0;

  @media only screen and (max-width: 767px) {
    display: inline-block;
  }

  input {
    background: var(--white);
    opacity: 0;
    position: relative;
    z-index: 2;
    max-width: 150px;
    cursor: pointer;
    display: block;
  }

  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-brown-gray);
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;
  }
}

.TextAreaInput {
  textarea {
    border: 1px solid var(--color-gray-light-2);
    border-radius: 5px;
    resize: none;
    width: 100%;
    padding-bottom: 22px;
  }

  .TextWithCounter {
    position: relative;
  }

  .TextCounter {
    position: absolute;
    color: var(--color-brown-gray);
    right: 10px;
    bottom: 10px;
  }

  .ErrorMessage {
    margin: 0;
  }
}

.disabled {
  color: var(--color-gray-light-2);
  opacity: 0.7;
  cursor: not-allowed;
}

.RadioInput {
  .ValueWrapper {
    display: flex;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      z-index: 2;
      cursor: pointer;

      + .ValueLabel {
        &:before {
          content: "";
          background: var(--white);
          border-radius: 100%;
          border: 1px solid var(--color-primary);
          display: inline-block;
          width: 15px;
          height: 15px;
          position: relative;
          margin-right: 12px;
          vertical-align: middle;
          cursor: pointer;
          text-align: center;
          transition: all 0.3s ease;
        }
      }

      &:checked {
        + .ValueLabel {
          &:before {
            background-color: var(--color-primary);
            box-shadow: inset 0 0 0 2px var(--white);
          }
        }
      }

      &:focus {
        + .ValueLabel {
          &:before {
            outline: none;
            border-color: var(--color-primary);
          }
        }
      }

      + .ValueLabel {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }

      &:disabled {
        color: var(--color-gray-light-2);
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    .ValueLabel {
      font-size: 13px;
      margin-bottom: 10px;
      padding-left: 2.5em;
      text-indent:-2.5em;
      word-break: break-all;

    }
  }
}

.RadioTabInput {
  .ValueWrapper {
    display: flex;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      z-index: 2;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      + .ValueContent {
        &:before {
          content: "";
          background: var(--white);
          border-bottom: 1px solid var(--color-gray-light-1);
          display: inline-block;
          position: relative;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 0.3s ease;
        }
      }

      &:checked {
        + .ValueBorder {
          background: var(--color-weird-green);
        }
      }

      &:focus {
        + .ValueContent {
          &:before {
            outline: none;
            border-color: var(--color-weird-green);
          }
        }
      }

      + .ValueContent {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }

    .ValueContent {
      padding: 0 5px 10px 5px;
      border-bottom: 1px solid var(--color-gray-light-1);
      // border-bottom: 1px solid #000;
    }

    .ValueBorder {
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 3px;
      background: transparent;
      border-radius: 3px;
    }
  }
}

.CheckboxInput {
  flex-direction: row;

  label {
    font-size: 12px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .ValueWrapper {
    display: flex;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      z-index: 2;
      cursor: pointer;

      + .ValueLabel {
        &:before {
          content: "";
          background: var(--white);
          border: 1px solid var(--color-brown-gray);
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          margin-right: 10px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 0.3s ease;
        }
      }

      &:checked {
        + .ValueLabel {
          &:before {
            background-color: var(--color-primary);
            box-shadow: inset 0 0 0 2px var(--white);
          }
        }
      }

      &:focus {
        + .ValueLabel {
          &:before {
            outline: none;
            border-color: var(--color-primary);
          }
        }
      }

      + .ValueLabel {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }

    .ValueLabel {
      font-size: 13px;
      margin-bottom: 10px;
      color: var(--color-dark);
    }
  }
}

.CheckboxToggleInput {
  display: flex;
  flex-direction: row;
  align-items: center;

  .LeftLabel {
    margin: 0;
    margin-right: 10px;
  }

  .RightLabel {
    margin: 0;
    margin-left: 10px;
  }

  .Switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 17px;
    margin: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .Slider {
        background-color: var(--color-gray-light-3);
      }

      &:checked + .Slider:before {
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
      }
    }

    .Slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-gray-light-3);
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 10px;

      &::before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        border-radius: 100%;
        left: 4px;
        bottom: 2px;
        background-color: var(--color-secondary-light);
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
}

.DropzoneInput {
  background-color: var(--color-gray-light-1);
  border-radius: 5px;

  &.DropzoneError {
    background-color: var(--color-danger-light);

    .Dropzone {
      color: var(--color-danger);
    }
  }

  .Dropzone {
    color: var(--color-brown-gray);
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
    text-align: center;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .DropzoneTooltip {
      left: 100%;
      bottom: 100%;
      min-width: 195px;

      div {
        text-align: left;
      }
    }

    .DropzoneInfoIcon {
      margin-left: 8px;
      width: 16px;
      height: 16px;
      fill: var(--color-dark);
      opacity: 0.3;

      path {
        fill: var(--color-dark);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .FileInput {
    input {
      padding: 0;
    }
  }

  .DropzoneInput {
    .Dropzone {
      .DropzoneTooltip {
        min-width: auto;
        width: 80vw;
        top: 100%;
        bottom: auto;
      }
    }
  }
}
