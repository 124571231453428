.Gallery {
  height: 100%;
  padding: 50px 0;
  //padding: 50px 9%;
}

.GalleryNoHeader {
  height: 100%;
  margin: 0 auto;
}

.RelatedPeas {
  margin: 50px 0;
}

@media (min-width: 1200px) {
  :global(.container-xl) {
    max-width: 1275px;
  }
}
