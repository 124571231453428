.SearchBoxContainer {
  width: 48%;
  margin-right: 20px;
  margin-left: 3%;
}

.SearchBox {
  font-size: 13px;
  line-height: 15px;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8e8e8e;

  @media only screen and (max-width: 1024px) {
    background-color: var(--color-gray-light-1);
  }

  &:global(.Dark) {
    @media only screen and (max-width: 767px) {
      background-color: #fff;
    }
  }
}

.Typewriter {
  width: fit-content;
}

.TypewriterSearchMode {
  width: 100%;
}

.Typewriter h3 {
  width: 100%;
  height: 2rem;
  position: relative;
  overflow: hidden;
  border-right: 0.15em solid #6b63ff;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  top: 30px;
  font-size: 24px;
  padding-top: 3px;
}

.Menu,
.Company,
.Interests {
  background-color: var(--color-gray-light-1);

  .Typewriter {
    width: 100%;
  }
}

.HeaderSB {
  background-color: var(--color-grey-light-3);
}

:global(.FlexStart) {
  justify-content: flex-start;
}

.SeriesToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  background-color: var(--color-white);

  .SeriesToggleCheckbox {
    margin-top: 0;

    label {
      font-size: 1rem;
    }
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--color-secondary-light);
  }
}

.MagnifyingGlass {
  height: 12px;
  width: 12px;
  position: absolute;
  left: 10px;
  opacity: 0.7;
}

.InputTypewriter {
  padding: 10px;
  padding-left: 2rem;
  width: 100%;
  border: none;
  background-color: var(--color-gray-light-1);
  border-radius: 5px;
  font-size: 13px;
  line-height: 15px;
  height: 35px;
  color: #8e8e8e;

  &:global(.Dark) {
    @media only screen and (max-width: 1024px) {
      background-color: #fff;
    }
  }
}

textarea:focus,
input:focus {
  outline: none;
}

.DefaultTooltip {
  padding: 10px 15px 0px 15px;
}

.FilterFormGroup {
  label {
    font-weight: 400;
  }
}

.FilterImage {
  margin-right: 10px;
}
.FilterOptions {
  box-shadow: rgba(18, 17, 17, 0.2) 0px 2px 8px 0px;
  padding: 4px 50px 4px 4px;
  position: "relative";
  right: "20px";
  z-index: 100;
  border-radius: 6px;
  font-size: 12px;

  p {
    padding: 5px;
    margin: 0;
    cursor: pointer;
  }
}
@media only screen and (min-width: 761px) and (max-width: 1024px) {
  .SearchBox {
    min-width: 18rem;

    &:global(.Dark) {
      background-color: white;
    }
  }

  .SearchBoxContainer {
    width: 100%;
    margin-right: 0;
  }

  .Gallery,
  .Menu,
  .Company,
  .Interests {
    margin-left: 0;
    min-width: 0;
    padding-right: 0;
  }

  .FilterImage {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 760px) {
  .SearchBox {
    min-width: 18rem;

    &:global(.Dark) {
      background-color: white;
    }
  }

  .SearchBoxContainer {
    width: 100%;
    margin-right: 0;
  }

  .Gallery,
  .Menu,
  .Company,
  .Interests {
    margin-left: 0;
    min-width: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .SearchBox {
    min-width: 100%;

    &:global(.Dark) {
      background-color: white;
    }
  }

  .SearchBoxContainer {
    width: 100%;
    margin-right: 0;
  }

  .Gallery,
  .Menu,
  .Company,
  .Interests {
    margin-left: 0;
    min-width: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 375px) {
  .InputTypewriter {
    font-size: 14px;
  }

  .SearchBox {
    padding: 2px;
  }
}
