.Badge {
    border-radius: 100px;
    background-color: #FB3E5D;
    padding: 0px 5px;
    font-size: .7rem;
    color: #fff;
    position: absolute;
    right: 13px;
    top: 3px;

    @media only screen and (max-width: 768px) {
        top: -2px;
        right: -4px;
        padding: 1px 5px;
        font-size: .6rem;
    }
}

.Empty {
    background-color: transparent;
    display: none;
}