.PBSelect {
  .SegmentModal {
    width: 100%;

    & > div {
      font-size: 13px;
      border: 1px solid var(--color-gray-light-2);
    }
  }

  .Header {
    width: 190px;

    & > div {
      border-radius: 8px;
      padding-top: 1px;
      padding-bottom: 0px;
      border-width: 2px;
      font-size: 1.3rem;
      border-color: var(--color-gray-light-2);
      color: var(--color-brown-gray) !important;
      font-weight: bold;

      svg {
        width: 25px;
        height: 8px;
      }
    }
  }

  .Company {
    width: 140px;

    & > div {
      font-size: 13px;
      border-width: 1px;
      border-radius: 5px;
      border-color: var(--color-gray-light-2);
      color: var(--color-brown-gray);
      font-weight: 600;

      svg {
        width: 20px;
        height: 6px;
      }
    }
  }
}
