.BroadcastDropdown {
  width: 100%;
  display: flex;
  align-items: center;

  .Header {
    .CloseIcon {
      width: 16px;
      height: 16px;

      & > * {
        fill: var(--color-dark);
      }
    }
  }

  .ContentWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--color-gray-light-3);

    .Content {
      width: 100%;
      height: fit-content;
      background-color: var(--color-gray-light-1);
      padding: 25px 15px;
    }
  }
}
