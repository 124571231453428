.Dropdown {
}

.HamburguerIcon {
  width: 22px;
  height: auto;
  margin-left: 15px;
}

.OpenButton {
  position: relative;
  cursor: pointer;
}

.DropdownItems {
  color: var(--color-dark);
  background-color: var(--color-gray-light-1);
  width: 18rem;
  position: absolute;
  right: 0px;
  top: 60px;
  z-index: 2000;
  // height: 43rem;
  height: auto;
  padding-bottom: 30px;
  border-radius: 8px;
}

.Backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  top: 0;
  left: 0;
}

.MenuDots {
  height: 30px;
  width: 30px;
  margin-left: 15px;
}

.MenuButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.CloseIcon {
  margin-left: 15px;
  height: 20px;
  width: 30px;
  // font-size: 26px;
  color: var(--color-dark);
  display: flex;
  align-items: flex-end;
}

.SocialMediaIcons {
  display: flex;
  justify-content: flex-start;
  padding: 12px 0px 0px 18px;

  .MediaIcon {
    width: 17px;
    height: 17px;
    color: var(--color-dark);
    margin-right: 7px;
  }
}


@media only screen and (max-width: 1000px) {
  .Dropdown {
    margin-left: 0;
    padding-top: 0px;
  }

  .MenuDots {
    display: none;
  }

  .MenuButton {
    display: block;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
  }

  .DropdownItems {
    width: 100%;
    height: 100vh;
    top: 70px;
    z-index: 100;
    position: fixed;
    border-radius: 0px;
    left: 0;
    overflow-y: scroll;
  }

  .SocialMediaIcons {
    display: flex;
    justify-content: flex-start;
    padding: 0px 0px 0px 18px;
    margin-top: 15px;
    margin-bottom: 20px;
    min-height: 10vh;
    
    .MediaIcon {
      width: 17px;
      height: 17px;
      color: var(--color-dark);
      margin-right: 7px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .DropdownItemsHome {
    top: 45px;
    height: 90vh;
  }
}
