.BusinessDropdown {
  position: relative;

  &.BroadcastPea {
    background: var(--color-dark);
    width: 22px;
    height: 22px;
    border-radius: 3px;
    margin-left: auto;

    .LinksWrapper {
      min-width: 220px;
    }
  }

  &.Comment {
    .LinksWrapper {
      min-width: auto;
      padding: 12px 15px;
    }
  }

  .BusinessDropdownButton {
    width: 100%;
    height: 100%;
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  .rightZero {
    right: 0;
  }

  .LinksWrapper {
    position: absolute;
    right: 0;
    background: var(--color-white);
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.102));
    min-width: 170px;
    padding: 15px 20px;
    margin-top: 5px;
    z-index: 2;
    border-radius: 10px;
    width: 100px;

    .heading {
      color: var(--color-dark);
      opacity: 0.5;
      margin-bottom: 10px;
      word-break: break-normal;
    }

    .TitleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      h4 {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    .Links {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }
}

@media only screen and (max-width: 576px) {
  .rightMiddle {
    left: 0 !important;
  }

  .LinksWrapper {
    margin-left: -73px;
    width: 100px;
  }
}
