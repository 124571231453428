.WrapperHome {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .ScrollableArea {
    padding-top: 20px;
    overflow-y: auto;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.gallery {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.SmallIconWrapper {
  z-index: -1;
}

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-bottom: 100px;
  position: relative;
}

.HomeTitle {
  padding: 1rem;
  //margin-top: 25vh;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 30px;
  line-height: normal;
  font-weight: normal;
  letter-spacing: 2.5px;

  h1 {
    font-family: "Work Sans", sans-serif;
    letter-spacing: 0px;
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    color: #2F2E41;
    font-weight: 400;

    strong{
      font-weight: 700;
      font-style: italic;
    }
  }
}

label {
  font-weight: bold;
  margin-bottom: 0em;
}

.searchBoxWrapper {
  input {
    caret-color: var(--color-primary);
  }
}

.Background {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.SmallIcon2 {
  top: 155px;
  right: 35%;
  height: 96px;
  float: right;
}

.SmallIcon5 {
  height: 53;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
  position: absolute;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .Filter {
    max-width: 350px;
  }
}

@media only screen and (max-width: 760px) {
  .HomeTitle {
    h3 {
      display: none;
    }
  }
  .Background {
    height: 100vh;
    width: 100vw;
  }

  .SmallIconWrapper {
    position: absolute;
    height: 100%;
    width: 100%;

    .SmallIcon1,
    .SmallIcon2,
    .SmallIcon3,
    .SmallIcon4 {
      position: relative;
      clear: both;
      z-index: -1;
    }

    .SmallIcon5{
      display: none;
    }

    .SmallIcon1 {
      top: 60px;
      right: 2rem;
      height: 9rem;
      float: right;
    }

    .SmallIcon2 {
      top: 80px;
      right: 35%;
      height: 96px;
      float: right;
    }

    .SmallIcon3 {
      height: 5rem;
      float: right;
      top: 61vh;
      right: 1rem;
    }

    .SmallIcon3Cookies {
      top: 50vh;
    }

    .SmallIcon4 {
      height: 8rem;
      top: 75.5vh;
      left: 1rem;
    }

    .SmallIcon4Cookies {
      top: 65vh;
    }
  }
}

@media only screen and (max-width: 375px) {
  .HomeTitle {
    h1 {
      font-size: 30px;
      margin-bottom: 0;
      line-height: 1.2;
    }
  }

  .Background {
    height: 90%;
    width: 100vw;
    top: 12.5%;
  }

  .Home {
    margin-bottom: 0;
  }

  .SmallIconWrapper {
    .SmallIcon2 {
      top: 55px;
      right: 30%;
    }

    .SmallIcon2Cookies {
      top: 75px;
      height: 90px;
    }

    .SmallIcon3 {
      height: 4rem;
      float: right;
      top: 63vh;
      right: 1rem;
    }

    .SmallIcon3Cookies {
      top: 57vh;
    }

    .SmallIcon3CookiesSafari {
      top: 50vh;
    }

    .SmallIcon3Safari {
      top: 55vh;
    }

    .SmallIcon4 {
      height: 5rem;
      top: 81.5vh;
      left: 0;
    }

    .SmallIcon4Cookies {
      top: 73vh;
    }

    .SmallIcon4CookiesSafari {
      top: 61.75vh;
    }

    .SmallIcon4Safari {
      top: 71.5vh;
    }
  }

  .WrapperHome {
    .ScrollableArea {
      padding-top: 30px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .HomeTitle {
    h1 {
      font-size: 30px;
      margin-top: 2rem;
    }
  }

  .HomeTitleCookies {
    h1 {
      font-size: 25px;
      margin-top: 3rem;
    }
  }

  .Background {
    height: 45%;
    width: 90vw;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
  }

  .BackgroundCookies {
    width: 100vw;
  }

  .Home {
    margin-bottom: 0;
    margin-top: 2rem;
  }

  .SmallIconWrapper {
    .SmallIcon2 {
      top: 70px;
      right: 30%;
      height: 80px;
    }

    .SmallIcon2Cookies {
      top: 55px;
    }

    .SmallIcon3 {
      height: 3rem;
      float: right;
      top: 63vh;
      right: 1rem;
    }

    .SmallIcon3Cookies {
      top: 55.5vh;
    }

    .SmallIcon3CookiesSafari {
      top: 45vh;
    }

    .SmallIcon3Safari {
      top: 55vh;
    }

    .SmallIcon4 {
      height: 4rem;
      top: 81.5vh;
      left: 0;
    }

    .SmallIcon4Cookies {
      top: 71.5vh;
    }

    .SmallIcon4CookiesSafari {
      top: 61.75vh;
    }

    .SmallIcon4Safari {
      top: 71.5vh;
    }
  }
}

@media only screen and (max-width: 320px) and (max-height: 454px) {
  .SmallIconWrapper {
    .SmallIcon3CookiesSafari {
      top: 51.5vh;
    }

    .SmallIcon3Safari {
      top: 55vh;
    }

    .SmallIcon4CookiesSafari {
      top: 64.75vh;
    }

    .SmallIcon4Safari {
      top: 71.5vh;
    }
  }
}
