:root {
  /* COLORS */
  --color-primary: #6b63ff;
  --color-primary-light: #857ffd;
  --color-primary-dark: #443aff;
  --color-danger: #ff5151;
  --color-danger-light: rgba(255, 81, 81, 0.2);

  --color-secondary-light: #40af6b;
  --color-secondary-dark: #3ba062;

  --color-tertiary-light: #dbe9ff;
  --color-tertiary-dark: rgb(182, 194, 212);

  --color-grey-light-1: #efefef;
  --color-grey-light-2: #eee;
  --color-grey-light-3: #dbdada;

  --color-grey-dark: #777;
  --color-grey-dark-2: #999;
  --color-grey-dark-3: #4a4a4a;
  --color-grey-dark-4: #49545a;

  --color-white: #fff;
  --color-white-rgb: 255, 255, 255;
  --color-black: #000;
  --color-black-rgb: 0, 0, 0;

  --color-yellow: #ffc107;

  --color-blue-light: #fafbfd;
  --color-gray-light-1: #efefef;
  --color-gray-light-2: #cccccc;
  --color-gray-light-3: #d8d8d8;
  --color-gray-light-4: #f5f5f5;
  --color-brown-gray: #8e8e8e;
  --color-brown-gray-2: #4a4a4a;
  --color-cornflower: #6b62ff;
  --color-dark: #2f2e41;
  --color-weird-green: #4ed986;
  --color-weird-green-rgb: 78, 217, 134;
  --cornflower: #6b62ff;
  --greyish-brown: #4a4a4a;
  --brown-grey: #8e8e8e;
  --weird-green: #4ed986;
  --very-light-pink: #cccccc;
  --dark: #2f2e41;
  --white: #ffffff;
  --color-blue: #0d6efd;
  --color-gray:#8E8E8E;
  --weird-green-light-1: #edfbf3;

  --color-transparent: transparent;

  /* //FONT */
  --default-font-size: 1.6rem;
  --default-font-family: "Work Sans", sans-serif;

  --opacity-1: 0.1;
}

html,
body,
:global(#root) {
  color: var(--color-dark);
  height: 100%;
}

.WrappedRoutes {
  height: calc(100% - 64px);
  position: relative;
}

.popover {
  z-index: 2001;
}
