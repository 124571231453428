.ButtonWrapper button {
  background: var(--color-cornflower);
  color: #fff;
}

.RecommendationInfo p {
  font-weight: 400;
  font-size: 19px;
  line-height: 140%;
  text-align: center;
  color: #2f2e41;
  margin-bottom: 0;
}

.snackBoxImg {
  width: 96px;
  height: 96px;
  margin: 0 auto;
  margin-top: 15px;
}

@media only screen and (max-width: 575px) {
  .buttonWrapperDesktop {
    display: none !important;
  }
}
