.DropdownItem {
  text-align: start;
  // height: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  span{
    padding: 1rem 0;
    flex-grow: 1;
    display: block;
  }
}

.FontColorPrimary {
  color: var(--color-primary);
}
.FontColorWeirdGreen {
  color: var(--color-weird-green);
}
.FontColorWeirdBlue {
  color: var(--color-blue);
}

.Separator {
  margin: 0 1rem;
  border-bottom: 2px solid var(--color-black);
}

.ArrowDownWrapper {
  margin-right: 1rem;
  flex-grow: 0 !important;

  .DownIcon {
    color: var(--color-dark);
  }
}

.DropdownItem:hover {
  background-color: var(--color-grey-dark-3);
  color: var(--color-grey-light-3);

  .ArrowDownWrapper {

    .DownIcon {
      color: var(--color-grey-light-3);
    }
  }
}
