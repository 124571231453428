.CustomInput {
  margin-bottom: 15px;
  // margin-bottom: 0.88vw;

  :global {
    .form-control:focus {
      border-color: var(--color-primary);
      box-shadow: unset;
    }
  }
}

.InputFormControl {
  //height: calc(2.25rem + 18px);
  height: 2.63vw;
  min-height: 30px;

  //font-size: 13px;
  font-size: 1.14vw;
  border-radius: 5px;
  border: 1px solid #ced4da;

  //padding-left: 19px;
  //padding-bottom: 0px;
}

.ErrorMessage {
  color: var(--color-danger);
  text-align: left;
  font-size: 0.7rem;
}

.InputWrapper {
  position: relative;

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .EyeButton {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);

    svg, img {
      width: 15px;
      height: 15px;
    }
  }
}
